import axios from "axios";
import rootVars from "../app/root.vars";
import { axiosNemConfig } from "../app/axios.interceptor";

const getAddressByZip = async (payload: { zip: string }) => {
  //NetMeteringInterface
  let ret = null;
  try {
    await axios
      .post(
        `${rootVars.nemBaseURL}/requests/get-address`,
        {
          zip: payload.zip,
        },
        axiosNemConfig
      )
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

const getOneUtilityProvider = async (lseId: number) => {
  let ret = null;
  try {
    await axios
      .get(`${rootVars.nemBaseURL}/utility-providers/${lseId}`, axiosNemConfig)
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

const getUtilityRatesOnlineById = async (payload: { lseId: number }) => {
  try {
    await axios
      .post(
        `${rootVars.nemBaseURL}/tariffs/get-all-online-by-lse-id`,
        {
          lseId: payload.lseId,
        },
        axiosNemConfig
      )
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    console.log(e);
  }
};

const getUtilityRatesById = async (payload: { lseId: number }) => {
  //NetMeteringInterface
  let ret = null;
  let utilityRatesDropdownOptions: { value: number; label: string }[] = [];
  try {
    await axios
      .post(
        `${rootVars.nemBaseURL}/tariffs/get-all-by-lse-id`,
        {
          lseId: payload.lseId,
        },
        axiosNemConfig
      )
      .then((response) => {
        ret = response.data;
        ret.forEach(
          (item: {
            tariffId: number;
            tariffCode: string;
            tariffName: string;
          }) => {
            utilityRatesDropdownOptions.push({
              value: item.tariffId,
              label: "[" + item.tariffCode + "] " + item.tariffName,
            });
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });

    return utilityRatesDropdownOptions;
  } catch (e) {
    console.log(e);
  }
};

const getNemTariffRates = async (payload: {
  tariffId: number;
  masterTariffId: number;
  buildingUseType: string;
}) => {
  let ret = null;
  try {
    await axios
      .post(
        `${rootVars.nemBaseURL}/tariffs/get-tariff-rates-by-tariff-id`,
        {
          tariffId: payload.tariffId,
          masterTariffId: payload.masterTariffId,
          buildingUseType: payload.buildingUseType,
        },
        axiosNemConfig
      )
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

const regenerateSolbidTariffs = async () => {
  let ret = null;
  try {
    await axios
      .post(
        `${rootVars.nemBaseURL}/tariffs/regenerates-solbid-rates`,
        {
          uuid: "e8b630f9-9381-41bc-be35-ed781f8c9eb2",
        },
        axiosNemConfig
      )
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

const getEnergyIntensity = async (payload: {
  buildingTypeId: number;
  climateZone: number;
}) => {
  let ret = null;
  try {
    await axios
      .post(
        `${rootVars.nemBaseURL}/energy-intensities/get-one`,
        {
          buildingTypeId: payload.buildingTypeId,
          climateZone: payload.climateZone,
        },
        axiosNemConfig
      )
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

const getBuildingTypes = async () => {
  let ret = null;
  try {
    await axios
      .get(`${rootVars.nemBaseURL}/building-types`, axiosNemConfig)
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

const updateCategory = async (payload: {
  tariffRateId: number;
  categoryId: number;
}) => {
  let ret = null;
  try {
    await axios
      .post(
        `${rootVars.nemBaseURL}/tariff-rates/update-category`,
        {
          tariffRateId: payload.tariffRateId,
          categoryId: payload.categoryId,
        },
        axiosNemConfig
      )
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return ret;
  } catch (e) {
    console.log(e);
  }
};

const updatePeak = async (payload: {
  tariffRateId: number;
  peakId: number;
}) => {
  let ret = null;
  try {
    await axios
      .post(
        `${rootVars.nemBaseURL}/tariff-rates/update-peak`,
        {
          tariffRateId: payload.tariffRateId,
          peakId: payload.peakId,
        },
        axiosNemConfig
      )
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return ret;
  } catch (e) {
    console.log(e);
  }
};

const getMaxMonthlyUse = async (payload: {
  buildingTypeId: number;
  climateZone: number;
  annualElectricUsage: number;
}) => {
  let ret = null;
  try {
    await axios
      .get(
        `${rootVars.nemBaseURL}/hourly-load-model/get-max/${payload.buildingTypeId}/${payload.climateZone}/${payload.annualElectricUsage}`,
        axiosNemConfig
      )
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

const getMaxDemand = async (payload: {
  buildingTypeId: number;
  climateZone: number;
  annualElectricUsage: number;
}) => {
  let ret = null;
  try {
    await axios
      .get(
        `${rootVars.nemBaseURL}/minute-load-model/max-demand-kw/${payload.buildingTypeId}/${payload.climateZone}/${payload.annualElectricUsage}`,
        axiosNemConfig
      )
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

interface LocationPayload {
  lat: string;
  lng: string;
}

let API_KEY = "AIzaSyAy_rwJdIJDflw6BI32YUhomjRlXAUKMgg";

const getZipByLocation = async (payload: LocationPayload): Promise<string> => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${payload.lat},${payload.lng}&key=${API_KEY}`
    );

    outerLoop: for (const result of response.data.results) {
      for (const component of result.address_components) {
        if (component.types.includes("postal_code")) {
          return component.short_name;
          // Use a label to break out of both loops at once
          break outerLoop;
        }
      }
    }

    throw new Error("Postal code not found.");
  } catch (error) {
    console.log(error);
    throw new Error("Failed to get postal code.");
  }
};

export const netMeteringServices = {
  getAddressByZip,
  getUtilityRatesById,
  getNemTariffRates,
  getEnergyIntensity,
  getBuildingTypes,
  updateCategory,
  updatePeak,
  getMaxMonthlyUse,
  getMaxDemand,
  getOneUtilityProvider,
  getZipByLocation,
  getUtilityRatesOnlineById,
  regenerateSolbidTariffs,
};
