import React from "react";
import { useAppSelector } from "../../app/hooks";
import { loadingState } from "../../app/app.slice";
import { Spinner } from "@blueprintjs/core";

const LoadingSpinner = (props: any) => {
  const loadingObj = useAppSelector(loadingState);
  return (
    <div style={{ color: "red" }}>
      <Spinner
        value={Object.keys(loadingObj).length > 0 ? undefined : 0}
        size={30}
        style={{ marginRight: "20px" }}
      />
    </div>
  );
};

export default LoadingSpinner;
