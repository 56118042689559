import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface SocketState {
  isInitialized: boolean;
  isConnected: boolean;
  socketId: string | null;
  currentRoom: string | null;
  userName: string | null;
  errors: string | null;
}

const initialState: SocketState = {
  isInitialized: false,
  isConnected: false,
  socketId: null,
  currentRoom: null,
  userName: null,
  errors: null,
};

export interface joinAndLogoff {
  room: string;
  name: string | null;
}

export const socketSlice = createSlice({
  name: "socket",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    initializeSocket: (state) => {
      state.isInitialized = true;
    },
    connectSocket: (state, action: PayloadAction<string>) => {
      state.isConnected = true;
      state.socketId = action.payload;
    },
    joinRoomRequest: (state, action: PayloadAction<joinAndLogoff>) => {
      state.currentRoom = action.payload.room;
      state.userName = action.payload.name;
    },
    joinRoomSuccess: (state, action: PayloadAction<joinAndLogoff>) => {
      state.currentRoom = action.payload.room;
      state.userName = action.payload.name;
    },
    leaveRoomRequest: (state, action: PayloadAction<joinAndLogoff>) => {
      state.currentRoom = action.payload.room;
      state.userName = action.payload.name;
    },
    leaveRoomSuccess: (state, action: PayloadAction<joinAndLogoff>) => {
      state.currentRoom = null;
      state.userName = null;
    },
    startChannelFailure: (state, action) => {
      state.errors = action.payload;
    },
  },
});

export const {
  initializeSocket,
  connectSocket,
  joinRoomRequest,
  joinRoomSuccess,
  leaveRoomRequest,
  leaveRoomSuccess,
  startChannelFailure,
} = socketSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectCurrentRoom = (state: RootState) => state.socket.currentRoom;
export const selectUserName = (state: RootState) => state.socket.userName;
export const socketId = (state: RootState) => state.socket.socketId;

export default socketSlice.reducer;
