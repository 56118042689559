import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getActiveSolarProgram,
  getIsLoading,
  getIsOpen,
  unsetSelected,
  updateSolarProgramRequest,
} from "./SolarPrograms.slice";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  FormGroup,
  HTMLSelect,
  Intent,
  NumericInput,
} from "@blueprintjs/core";
import { Styles } from "../../styles/main.styled";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { SolarProgram } from "./SolarPrograms.initialState";

export const SolarProgramsDialog = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getIsLoading);
  const isOpen = useAppSelector(getIsOpen);
  const activeSolarProgram = useAppSelector(getActiveSolarProgram);

  const SolarProgramSchema = Yup.object().shape({
    solarRateType: Yup.string().required("Solar Rate Type is required"),
    solarProgramType: Yup.string().required("Solar Program is required"),
    solarRate: Yup.string().required("Solar Rate is required"),
  });

  const {
    control,
    handleSubmit,
    //getValues,
    //setValue,
    //register,
    //watch,
    //formState,
    formState: { errors },
    reset,
  } = useForm<SolarProgram>({
    resolver: yupResolver(SolarProgramSchema),
    defaultValues: activeSolarProgram,
  });

  useEffect(() => {
    reset({ ...activeSolarProgram });
  }, [reset, activeSolarProgram]);

  const onSubmit = handleSubmit((data) => {
    dispatch(updateSolarProgramRequest(data));
  });

  return (
    <Dialog
      title={`Solar Program: [${activeSolarProgram?.lse?.lseCode}] ${activeSolarProgram?.lse?.name}`}
      icon="info-sign"
      style={{ width: "600px" }}
      isOpen={isOpen}
      onClose={() => dispatch(unsetSelected())}
    >
      <form onSubmit={onSubmit}>
        <DialogBody>
          <Styles.FormRow>
            <Controller
              control={control}
              name="solarProgramType"
              rules={{ required: true }}
              render={({ field: { onChange, name, onBlur, value, ref } }) => (
                <FormGroup
                  style={{ width: "100%" }}
                  helperText={
                    errors.solarProgramType && (
                      <span>This field is required</span>
                    )
                  }
                  label="Solar Program Type"
                  labelFor={name}
                  labelInfo="*"
                  intent={errors.solarProgramType && Intent.DANGER}
                >
                  <HTMLSelect
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    disabled={isLoading}
                    style={{ width: "200px" }}
                    options={[
                      { value: "1", label: "1" },
                      { value: "2", label: "2" },
                    ]}
                  />
                </FormGroup>
              )}
            />
            <Controller
              control={control}
              name="solarRateType"
              rules={{ required: true }}
              render={({ field: { onChange, name, onBlur, value, ref } }) => (
                <FormGroup
                  style={{ width: "100%" }}
                  helperText={
                    errors.solarRateType && <span>This field is required</span>
                  }
                  label="Solar Rate Type"
                  labelFor={name}
                  labelInfo="*"
                  intent={errors.solarRateType && Intent.DANGER}
                >
                  <HTMLSelect
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    disabled={isLoading}
                    style={{ width: "200px" }}
                    options={[
                      { value: "1", label: "1" },
                      { value: "2", label: "2" },
                    ]}
                  />
                </FormGroup>
              )}
            />
          </Styles.FormRow>
          <Styles.FormRow>
            <Controller
              control={control}
              name="solarRate"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormGroup
                  label="Solar Rate"
                  labelFor={name}
                  labelInfo={"*"}
                  style={{ marginRight: "16px" }}
                >
                  <NumericInput
                    style={{ width: "120px" }}
                    id={name}
                    name={name}
                    onBlur={onBlur}
                    onValueChange={onChange}
                    value={value}
                    minorStepSize={0.00001}
                    stepSize={0.01}
                    asyncControl={true}
                    min={0.0}
                    placeholder={"0.00000"}
                    inputRef={ref}
                    intent={errors.solarRate && Intent.DANGER}
                  />
                </FormGroup>
              )}
            />
          </Styles.FormRow>
        </DialogBody>
        <DialogFooter
          actions={
            <Button
              intent={"primary"}
              text={"Update"}
              loading={isLoading}
              type={"submit"}
            />
          }
        />
      </form>
    </Dialog>
  );
};
