import * as React from "react";
import { Card, Elevation, HTMLTable } from "@blueprintjs/core";
import { CardHeader } from "../../components/Card/CardHeader";
import { Styles } from "../../styles/main.styled";
import {
  getSolbidTariffRequest,
  getTariffsTable,
  getTariffsWithErrorsRequest,
  isNetMeteringLoading,
} from "../NetMetering.slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect } from "react";
import { SolbidTariffForm } from "../solbid-tariffs/SolbidTariff.form";
import { TariffRatesDetails } from "../dialogs/TariffRatesDetails";

export const ErrorsView = () => {
  const dispatch = useAppDispatch();
  const tariffs = useAppSelector(getTariffsTable);
  const isLoading = useAppSelector(isNetMeteringLoading);

  useEffect(() => {
    dispatch(getTariffsWithErrorsRequest());
  }, [dispatch]);

  return (
    <>
      <div>
        <br />
        <br />
        <Styles.CardContainer>
          <Card
            elevation={Elevation.TWO}
            style={{ padding: "0", width: "94%" }}
          >
            <CardHeader
              leftIcon={"helper-management"}
              title={"All Tariffs with errors (" + tariffs.length + ")"}
            />
            <HTMLTable interactive={true} bordered={true} striped={true}>
              <thead>
                <tr>
                  <th style={{ width: "100px" }}>tariffId</th>
                  <th style={{ width: "100px" }}>lseId</th>
                  <th style={{ width: "100px" }}>state</th>
                  <th style={{ width: "300px" }}>provider</th>
                  <th style={{ width: "100px" }}>tariffCode</th>
                  <th>tariffName</th>
                </tr>
              </thead>
              <tbody>
                {tariffs && tariffs.length > 0 ? (
                  tariffs.map((row, index) => (
                    <tr
                      key={index}
                      onClick={() => dispatch(getSolbidTariffRequest(row))}
                    >
                      <td>{row.tariffId}</td>
                      <td>{row.lseId}</td>
                      <td>
                        {row.territory
                          ? row.territory.state
                            ? row.territory.state.name
                            : ""
                          : ""}
                      </td>
                      <td>{row.lse ? row.lse.name : ""}</td>
                      <td>{row.tariffCode}</td>
                      <td>{row.tariffName}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} style={{ textAlign: "center" }}>
                      {isLoading ? "Loading..." : "NO DATA"}
                    </td>
                  </tr>
                )}
              </tbody>
            </HTMLTable>
          </Card>
        </Styles.CardContainer>
      </div>
      <SolbidTariffForm />
      <TariffRatesDetails />
    </>
  );
};
