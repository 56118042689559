import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./Auth.initialState";
import { ILogin, IRegister } from "./Auth.interfaces";
import { RootState } from "../../../app/store";

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    appInit: (state) => {
      state.appInit = true;
    },
    loginRequest: (state, action: PayloadAction<ILogin>) => {
      state.loggingIn = true;
      state.data = action.payload;
    },
    loginSuccess: (state, action: PayloadAction<ILogin>) => {
      state.loggedIn = true;
      state.loginInfo = {};
    },
    loginError: (state, action: PayloadAction<ILogin>) => {
      state.loggedIn = false;
      state.loggingIn = false;
    },
    registerRequest: (state, action: PayloadAction<IRegister>) => {
      state.loggingIn = true;
      state.data = action.payload;
    },
    registerSuccess: (state, action: PayloadAction<IRegister>) => {
      state.loggedIn = true;
      state.loginInfo = {};
    },
    registerError: (state, action: PayloadAction<IRegister>) => {
      state.loggedIn = false;
      state.loggingIn = false;
    },
    userSet: (state, action: PayloadAction<any>) => {
      state.loggedIn = true;
      state.loggingIn = false;
      state.user = action.payload.user;
      state.session = action.payload.security;
      state.data = {};
    },
    logoutRequest: (state, action: PayloadAction<any>) => {},
    logoutSuccess: (state, action: PayloadAction<any>) => {
      state.loggedIn = false;
      state.loggingIn = false;
      state.session = {};
      state.data = {};
    },
  },
});

export const loggedIn = (state: RootState) => state.auth.loggedIn;
export const userSession = (state: RootState) => state.auth.session;
export const loggingIn = (state: RootState) => state.auth.loggingIn;
export const user = (state: RootState) => state.auth.user;
export const userId = (state: RootState) => state.auth.user.id;
export const userRoles = (state: RootState) => state.auth.user.roles;

export const {
  appInit,
  loginRequest,
  loginSuccess,
  loginError,
  registerRequest,
  registerSuccess,
  registerError,
  userSet,
  logoutSuccess,
  logoutRequest,
} = authSlice.actions;
export default authSlice.reducer;
