import styled from "styled-components";

const WelcomeComponent = styled.div`
  margin: 125px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
`;

const WelcomeCard = styled.div`
  margin: 20px;
  .bp4-card {
    border-style: solid;
    border-color: #3a3a3a;
    border-radius: 10px;
    border-width: 0.5px;
    color: #8abbff;
    width: 250px;
    height: 150px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;

    .bp4-heading {
      font-size: 20px;
    }
  }
`;

export const Styles = {
  WelcomeComponent,
  WelcomeCard,
};
