import {
  call,
  put,
  delay,
  select,
  takeLatest,
  PutEffect,
  CallEffect,
  ForkEffect,
  SelectEffect,
} from "@redux-saga/core/effects";
import { netMeteringServices } from "./NetMetering.services";
import {
  formData,
  getBuildingTypesRequest,
  getBuildingTypesSuccess,
  getEnergyIntensityRequest,
  getEnergyIntensitySuccess,
  getLocation,
  getMaxDemandRequest,
  getMaxDemandSuccess,
  getMaxMonthlyUseRequest,
  getMaxMonthlyUseSuccess,
  getOneUtilityRequest,
  getOneUtilitySuccess,
  getTariffRateRequest,
  getTariffRateSuccess,
  getUtilityRateError,
  getUtilityRatesOnlineRequest,
  getUtilityRatesOnlineSuccess,
  getUtilityRatesRequest,
  getUtilityRatesSuccess,
  getZipCode,
  netMeteringError,
  netMeteringRequest,
  netMeteringSuccess,
  processStreet,
  regenerateSolbidRatesRequest,
  regenerateSolbidRatesSuccess,
  setBuildingTypeId,
  setPeakCategoryRequest,
  setPeakCategorySuccess,
  setRateCategoryRequest,
  setRateCategorySuccess,
  setTariffId,
} from "./NetMetering.slice";

// Saga Workers
export function* fetchNetMeteringRequest({
  payload,
}: any): Generator<CallEffect | PutEffect | ForkEffect> {
  try {
    const response = yield call(netMeteringServices.getAddressByZip, payload);
    yield put(netMeteringSuccess(response));
  } catch (error: any) {
    yield put(netMeteringError("Error: 032443"));
  }
}

export function* fetchEnergyIntensity(): Generator<
  CallEffect | PutEffect | ForkEffect | SelectEffect
> {
  try {
    // @ts-ignore
    const payload: {
      buildingTypeId: number;
      climateZone: number;
    } = yield select(formData);
    const response = yield call(
      netMeteringServices.getEnergyIntensity,
      payload
    );
    yield put(getEnergyIntensitySuccess(response));
    // @ts-ignore
    const maxMonthlyVals: {
      buildingTypeId: number;
      climateZone: number;
      annualKwh: number;
    } = yield select(formData);
    yield put(
      getMaxMonthlyUseRequest({
        buildingTypeId: maxMonthlyVals.buildingTypeId,
        climateZone: maxMonthlyVals.climateZone,
        annualElectricUsage: maxMonthlyVals.annualKwh,
      })
    );
    yield put(
      getMaxDemandRequest({
        buildingTypeId: maxMonthlyVals.buildingTypeId,
        climateZone: maxMonthlyVals.climateZone,
        annualElectricUsage: maxMonthlyVals.annualKwh,
      })
    );
  } catch (error: any) {}
}

export function* fetchGetEnergyIntensity(): Generator<
  CallEffect | PutEffect | ForkEffect | SelectEffect
> {
  try {
    yield put(getEnergyIntensityRequest());
  } catch (error: any) {}
}

export function* fetchUtilityRates({
  payload,
}: any): Generator<CallEffect | PutEffect | ForkEffect> {
  try {
    const response = yield call(netMeteringServices.getUtilityRatesById, {
      lseId: payload,
    });
    // @ts-ignore
    if (response.length === 0) {
      yield put(getUtilityRatesOnlineRequest(payload));
    } else {
      yield put(getUtilityRatesSuccess(response));
    }
  } catch (error: any) {
    yield put(getUtilityRateError("Error: 032434"));
  }
}
export function* fetchUtilityRatesAgain({
  payload,
}: any): Generator<CallEffect | PutEffect | ForkEffect> {
  try {
    const response = yield call(netMeteringServices.getUtilityRatesById, {
      lseId: payload,
    });
    // @ts-ignore
    if (response.length === 0) {
      yield put(getUtilityRateError("No utility rates available"));
    } else {
      yield put(getUtilityRatesSuccess(response));
    }
  } catch (error: any) {
    yield put(getUtilityRateError("Error: 032434"));
  }
}

export function* fetchUtilityRatesOnline({ payload }: any): Generator<any> {
  try {
    yield call(netMeteringServices.getUtilityRatesOnlineById, {
      lseId: payload,
    });
    yield delay(5000); // Wait for 5 second
    yield put(getUtilityRatesOnlineSuccess(payload));
  } catch (error: any) {
    yield put(getUtilityRateError("Error: 032434"));
  }
}

function* executeTariffIdAction(): Generator<
  PutEffect | SelectEffect | CallEffect
> {
  yield put(getTariffRateRequest());
}

function* fetchTariffRateCalculator(): Generator<
  PutEffect | SelectEffect | CallEffect
> {
  try {
    // @ts-ignore
    const formValues: {
      tariffId: number;
      masterTariffId: number;
      buildingUseType: string;
    } = yield select(formData);
    const response = yield call(netMeteringServices.getNemTariffRates, {
      tariffId: formValues.tariffId,
      masterTariffId: formValues.masterTariffId,
      buildingUseType: formValues.buildingUseType,
    });
    yield put(getTariffRateSuccess(response));
  } catch (e) {}
}

function* fetchBuildingTypes(): Generator<
  PutEffect | SelectEffect | CallEffect
> {
  try {
    const response = yield call(netMeteringServices.getBuildingTypes);
    yield put(getBuildingTypesSuccess(response));
  } catch (e) {}
}

function* fetchUpdateRateCategory({
  payload,
}: any): Generator<PutEffect | SelectEffect | CallEffect> {
  try {
    yield call(netMeteringServices.updateCategory, payload);
    yield put(setRateCategorySuccess(payload));
  } catch (e) {}
}

function* fetchUpdatePeakCategory({
  payload,
}: any): Generator<PutEffect | SelectEffect | CallEffect> {
  try {
    yield call(netMeteringServices.updatePeak, payload);
    yield put(setPeakCategorySuccess(payload));
  } catch (e) {}
}

function* fetchMaxMonthlyUse({
  payload,
}: any): Generator<PutEffect | SelectEffect | CallEffect> {
  try {
    const response = yield call(netMeteringServices.getMaxMonthlyUse, payload);
    yield put(getMaxMonthlyUseSuccess(response));
  } catch (e) {}
}

function* fetchMaxDemand({
  payload,
}: any): Generator<PutEffect | SelectEffect | CallEffect> {
  try {
    const response = yield call(netMeteringServices.getMaxDemand, payload);
    yield put(getMaxDemandSuccess(response));
  } catch (e) {}
}

function* fetchOneUtility({
  payload,
}: any): Generator<PutEffect | SelectEffect | CallEffect> {
  try {
    const response = yield call(
      netMeteringServices.getOneUtilityProvider,
      payload
    );
    yield put(getOneUtilitySuccess(response));
  } catch (e) {}
}

function* fetchRegenerateSolbidRates({
  payload,
}: any): Generator<PutEffect | SelectEffect | CallEffect> {
  try {
    yield call(netMeteringServices.regenerateSolbidTariffs);
    yield put(regenerateSolbidRatesSuccess());
  } catch (e) {}
}

function* fetchZipCode({
  payload,
}: any): Generator<PutEffect | SelectEffect | CallEffect> {
  try {
    const zip = yield select(getZipCode);
    if (zip) {
      yield put(netMeteringRequest({ zip }));
    } else {
      const location = yield select(getLocation);
      const response = yield call(
        // @ts-ignore
        netMeteringServices.getZipByLocation,
        location
      );
      yield put(netMeteringRequest({ zip: response }));
    }
  } catch (e) {}
}

// Saga Watchers

export function* watchUpdateZipCode() {
  yield takeLatest(netMeteringRequest, fetchNetMeteringRequest);
}

export function* watchUtilityRates() {
  yield takeLatest(getUtilityRatesRequest, fetchUtilityRates);
}

export function* watchUtilityRatesSecondTry() {
  yield takeLatest(getUtilityRatesOnlineSuccess, fetchUtilityRatesAgain);
}

export function* watchUtilityRatesOnline() {
  yield takeLatest(getUtilityRatesOnlineRequest, fetchUtilityRatesOnline);
}

export function* watchTariffIdState() {
  yield takeLatest(setTariffId, executeTariffIdAction);
}

export function* watchTariffRateSaga() {
  yield takeLatest(getTariffRateRequest, fetchTariffRateCalculator);
}
export function* watchSetBuildingSaga() {
  yield takeLatest(setBuildingTypeId, fetchGetEnergyIntensity);
}

export function* watchGetEnergyIntensitySaga() {
  yield takeLatest(getEnergyIntensityRequest, fetchEnergyIntensity);
}

export function* watchGetBuildingTypesSaga() {
  yield takeLatest(getBuildingTypesRequest, fetchBuildingTypes);
}

export function* watchUpdateRateCategorySaga() {
  yield takeLatest(setRateCategoryRequest, fetchUpdateRateCategory);
}
export function* watchUpdatePeakCategorySaga() {
  yield takeLatest(setPeakCategoryRequest, fetchUpdatePeakCategory);
}
export function* watchGetMaxMonthlyUseSaga() {
  yield takeLatest(getMaxMonthlyUseRequest, fetchMaxMonthlyUse);
}
export function* watchGetMaxDemandSaga() {
  yield takeLatest(getMaxDemandRequest, fetchMaxDemand);
}
export function* watchGetOneUtilitySaga() {
  yield takeLatest(getOneUtilityRequest, fetchOneUtility);
}
export function* watchGoogleStreetRequestSaga() {
  yield takeLatest(processStreet, fetchZipCode);
}
export function* watchRegenerateSolbidRates() {
  yield takeLatest(regenerateSolbidRatesRequest, fetchRegenerateSolbidRates);
}
