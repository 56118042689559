import {
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeLatest,
} from "@redux-saga/core/effects";
import {
  loginRequest,
  loginSuccess,
  loginError,
  userSet,
  registerSuccess,
  registerError,
  registerRequest,
  logoutSuccess,
  logoutRequest,
  appInit,
} from "./Auth.slice";
import Cookies from "js-cookie";
import { authService } from "./Auth.services";
import { selectUser } from "./Auth.selectors";
import { appHost } from "../../../app/root.vars";
import { initializeSocket } from "../../../web-sockets/socketSlice";

// Saga Workers

function* fetchLogin({
  payload,
}: any): Generator<CallEffect | PutEffect | ForkEffect> {
  try {
    const response: any = yield call(authService.login, payload);
    yield call(authService.setCookie, response.data);
    yield put(userSet(response.data));
    yield put(loginSuccess);
  } catch (error: any) {
    yield put(loginError(error));
  }
}

export function* fetchRegister({
  payload,
}: any): Generator<CallEffect | PutEffect | ForkEffect> {
  try {
    const response: any = yield call(authService.register, payload);
    yield put(registerSuccess);
    Cookies.set("user", JSON.stringify(response.data), {
      expires: 7,
      secure: false,
      domain: appHost,
    });
    yield put(userSet(response.data));
  } catch (error: any) {
    yield put(registerError(error));
  }
}

function* logout(): Generator<PutEffect | SelectEffect | CallEffect> {
  const user = yield select(selectUser);
  yield call(authService.logout);

  if (user) {
    yield put(logoutSuccess(user));
    //yield call(authService.redirectHome);
  }
}

function* startApp(): Generator<PutEffect | SelectEffect | CallEffect> {
  const user = yield select(selectUser);
  if (user) {
    yield put(initializeSocket());
  }
}

function* fetchAppInit(): Generator<PutEffect | SelectEffect | CallEffect> {
  const user = yield select(selectUser);
  if (user) {
    // yield put(initializeSocket());
  }
}

// Saga Watchers
export function* watchAppInit() {
  yield takeLatest(appInit, fetchAppInit);
}

export function* watchLogin() {
  yield takeLatest(loginRequest, fetchLogin);
}

export function* watchRegister() {
  yield takeLatest(registerRequest, fetchRegister);
}

export function* watchUserSet() {
  yield takeLatest(userSet, startApp);
}

export function* watchLogoutUser() {
  yield takeLatest(logoutRequest, logout);
}
