import { createSlice } from "@reduxjs/toolkit";
import {
  DropdownValues,
  GoogleAddressComponent,
  initialState,
  SolbidTableTariffs,
} from "./NetMetering.initialState";
import { RootState } from "../app/store";

export interface TariffRate {
  tariffRateId: number;
  peakId: number;
  categoryId: number;
  categoryR: string;
  rateGroupName: string;
  rateName: string;
  tariffCode: string;
  tariffName: string;
  quantityKey: string;
  rateAmount: number;
  transactionType: string;
}

const netMeteringSlice = createSlice({
  name: "netMetering",
  initialState,
  reducers: {
    netMeteringRequest(state, action) {
      state.isLoading = true;
      state.isLoaded = false;
      // state.data.utility = [];
    },
    netMeteringSuccess(state, action) {
      state.isLoading = false;
      state.isLoaded = true;
      state.data.city = action.payload.city;
      state.data.zip = action.payload.zip;
      state.data.taxRate = action.payload.state.rate;
      state.data.stateId = action.payload.state.id;
      state.data.state = action.payload.state.name;
      state.data.stateAbbr = action.payload.state.abbr;
      state.data.climate = action.payload.county.climate;
      state.data.climateZone = action.payload.county.climateZone;
      state.data.countyFullName = action.payload.county.fullName;
      state.data.countyName = action.payload.county.name;
      state.data.countyType = action.payload.county.type;
      state.data.humidity = action.payload.county.humidity;
      state.data.utility = action.payload.utility;
    },
    netMeteringError(state, action) {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = action.payload;
    },
    getUtilityRatesRequest(state, action) {
      state.isLoading = true;
      state.data.lseId = +action.payload;
    },
    getUtilityRatesSuccess(state, action) {
      state.isLoading = false;
      state.data.utilityRates = action.payload;
    },
    getUtilityRatesOnlineRequest(state, action) {
      state.isLoading = true;
      state.data.lseId = +action.payload;
    },
    getUtilityRatesOnlineSuccess(state, action) {
      state.isLoading = false;
      state.data.lseId = +action.payload;
    },
    getUtilityRateError(state, action) {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = action.payload;
    },
    setBuildingTypeId(state, action) {
      state.data.buildingUseType = action.payload;

      const index = state.data.buildingTypes.findIndex(
        (building) => building.value === action.payload
      );
      if (index !== -1)
        state.data.buildingTypeId = state.data.buildingTypes[index].id;
    },
    setTariffId(state, action) {
      state.data.tariffId = +action.payload;
    },
    getTariffRateRequest(state) {
      state.isLoading = true;
    },
    getTariffRateSuccess(state, action) {
      const { fixedFee, supplyFee, deliveryFee, demandFee } = findFees(
        action.payload
      );
      state.data.supplyFee = supplyFee;
      state.data.fixedFee = fixedFee;
      state.data.deliveryFee = deliveryFee;
      state.data.demandFee = demandFee;
      state.data.tariffRates = action.payload;
      state.isLoading = false;
    },
    getBuildingTypesRequest(state) {
      state.isLoading = true;
    },
    getBuildingTypesSuccess(state, action) {
      state.isLoading = false;
      state.data.buildingTypes = action.payload;

      let arr: DropdownValues[] = [];
      action.payload.forEach((building: { id: number; label: string }) => {
        arr.push({
          value: building.id.toString(),
          label: building.label,
        });
      });
      state.solbidRates.buildingsDropdown = arr;
    },
    getEnergyIntensityRequest(state) {
      state.isLoading = true;
    },
    getEnergyIntensitySuccess(state, action) {
      state.isLoading = false;
      state.data.energyIntensity = action.payload;
      if (
        state.data.buildingSqft &&
        state.data.energyIntensity &&
        state.data.annualKwh === 0
      ) {
        state.data.annualKwh =
          state.data.buildingSqft * state.data.energyIntensity;
      }
    },
    setRateCategoryRequest(state, action) {
      state.isLoading = true;
    },
    setRateCategorySuccess(state, action) {
      state.isLoading = false;

      const index = state.data.tariffRates.findIndex(
        (item) => item.tariffRateId === action.payload.tariffRateId
      );
      if (index !== -1)
        state.data.tariffRates[index].categoryId = action.payload.categoryId;

      const { fixedFee, supplyFee, deliveryFee, demandFee } = findFees(
        state.data.tariffRates
      );
      state.data.supplyFee = supplyFee;
      state.data.fixedFee = fixedFee;
      state.data.deliveryFee = deliveryFee;
      state.data.demandFee = demandFee;
    },
    setPeakCategoryRequest(state, action) {
      state.isLoading = true;
    },
    setPeakCategorySuccess(state, action) {
      state.isLoading = false;

      const index = state.data.tariffRates.findIndex(
        (item) => item.tariffRateId === action.payload.tariffRateId
      );
      if (index !== -1)
        state.data.tariffRates[index].peakId = action.payload.peakId;

      const { fixedFee, supplyFee, deliveryFee, demandFee } = findFees(
        state.data.tariffRates
      );
      state.data.supplyFee = supplyFee;
      state.data.fixedFee = fixedFee;
      state.data.deliveryFee = deliveryFee;
      state.data.demandFee = demandFee;
    },
    setBuildingSqft(state, action) {
      state.data.buildingSqft = parseInt(action.payload);
    },
    getMaxMonthlyUseRequest(state, action) {
      state.isLoading = true;
    },
    getMaxMonthlyUseSuccess(state, action) {
      state.isLoading = false;
      state.data.maxMonthlyUse = action.payload;
    },
    getMaxDemandRequest(state, action) {
      state.isLoading = true;
    },
    getMaxDemandSuccess(state, action) {
      state.isLoading = false;
      state.data.maxDemand = action.payload;
    },
    getOneUtilityRequest(state, action) {
      state.isLoading = true;
    },
    getOneUtilitySuccess(state, action) {
      state.isLoading = false;
      state.data.selectedUtility = action.payload;
      state.data.solBidProgramType = action.payload.solarRate.solBidProgramType;
      state.data.solarRateType = action.payload.solarRate.solarRateType;
      state.data.solarRate = action.payload.solarRate.solarRate;
      state.data.solarProgramCap = action.payload.solarRate.solarProgramCap;
    },
    processStreet(state, action) {
      state.isLoading = false;
      state.data.googleAddress = action.payload;
      state.data.location.lat =
        // @ts-ignore
        state.data.googleAddress.geometry.location.lat();
      state.data.location.lng =
        // @ts-ignore
        state.data.googleAddress.geometry.location.lng();
      state.data.street =
        state.data.googleAddress.formatted_address.split(",")[0];
      state.data.zip = loopAddressComponents(
        state.data.googleAddress.address_components
      );
    },
    getTariffsRequest(state, action) {
      state.isLoading = true;
      state.solbidRates.selectedUtility = action.payload;
      state.solbidRates.tariffs = [];
    },
    getTariffsSuccess(state, action) {
      state.isLoading = false;
      state.solbidRates.tariffs = action.payload;
    },
    getTariffsError(state, action) {
      state.isLoading = false;
    },
    getTariffsWithErrorsRequest(state) {
      state.isLoading = true;
      state.solbidRates.tariffs = [];
    },
    getTariffsWithErrorsSuccess(state, action) {
      state.isLoading = false;
      state.solbidRates.tariffs = action.payload;
    },
    getTariffsWithErrorsError(state, action) {
      state.isLoading = false;
    },
    getTariffsDropdownSuccess(state, action) {
      state.isLoading = false;
      let arr: DropdownValues[] = [];
      action.payload.forEach((tariff: SolbidTableTariffs) => {
        if (tariff.isActive) {
          arr.push({
            value: tariff.tariffId.toString(),
            label: tariff.tariffCode + ": " + tariff.tariffName,
          });
        }
      });
      state.solbidRates.tariffsDropdown = arr;
    },
    getTariffsDropdownError(state, action) {
      state.isLoading = false;
    },
    getOnlineTariffsRequest(state, action) {
      state.isLoading = true;
    },
    getOnlineTariffsSuccess(state) {
      state.isLoading = false;
    },
    getOnlineTariffsError(state, action) {
      state.isLoading = false;
    },
    getStatesDropdownRequest(state) {
      state.isLoading = true;
    },
    getStatesDropdownSuccess(state, action) {
      state.isLoading = false;
      let arr: DropdownValues[] = [];
      action.payload.forEach((state: { id: string; name: string }) => {
        arr.push({
          value: state.id,
          label: state.name,
        });
      });

      state.solbidRates.statesDropdown = arr;
      state.data.buildingTypeId = 1;
      state.data.buildingUseType = "largeOffice";
    },
    getStatesDropdownError(state, action) {
      state.isLoading = false;
    },
    getUtilitiesDropdownRequest(state, action) {
      state.isLoading = true;
      state.solbidRates.selectedState = action.payload;
      state.solbidRates.selectedUtility = "0";
      state.solbidRates.tariffs = [];
    },
    getUtilitiesDropdownSuccess(state, action) {
      state.isLoading = false;
      state.solbidRates.utilitiesDropdown = action.payload;
    },
    getUtilitiesDropdownError(state, action) {
      state.isLoading = false;
    },
    toggleTariffModal(state) {
      state.solbidRates.isModalOpen = !state.solbidRates.isModalOpen;
      if (!state.solbidRates.isModalOpen) {
        state.solbidRates.selectedTariff =
          initialState.solbidRates.selectedTariff;
        state.solbidRates.solbidRatesForm =
          initialState.solbidRates.solbidRatesForm;
      }
    },
    getSolbidTariffRequest(state, action) {
      state.isLoading = true;
      state.solbidRates.selectedTariff = action.payload;
      state.solbidRates.isModalOpen = true;
    },
    getSolbidRatesRequest(state, action) {
      state.isLoading = true;
    },
    getSolbidRatesSuccess(state, action) {
      state.isLoading = false;
      state.solbidRates.solbidRatesForm = action.payload;
    },
    getSolbidRatesError(state, action) {
      state.isLoading = false;
    },
    submitSolbidRatesRequest(state, action) {
      state.isLoading = true;
    },
    submitSolbidRatesSuccess(state) {
      state.isLoading = false;
      state.solbidRates.isModalOpen = false;
      state.solbidRates.selectedTariff =
        initialState.solbidRates.selectedTariff;
      state.solbidRates.solbidRatesForm =
        initialState.solbidRates.solbidRatesForm;
    },
    submitSolbidRatesError(state, action) {
      state.isLoading = false;
    },
    toggleTariffActiveRequest(state, action) {
      state.isLoading = true;
    },
    toggleTariffActiveSuccess(state) {
      state.isLoading = false;
      state.solbidRates.isModalOpen = false;

      const index = state.solbidRates.tariffs.findIndex(
        (tariff) =>
          tariff.tariffId === state.solbidRates.selectedTariff.tariffId
      );
      if (index !== -1)
        state.solbidRates.tariffs[index].isActive =
          !state.solbidRates.tariffs[index].isActive;

      state.solbidRates.selectedTariff =
        initialState.solbidRates.selectedTariff;
      state.solbidRates.solbidRatesForm =
        initialState.solbidRates.solbidRatesForm;
    },
    toggleGenabilityModal(state, action) {
      state.isGenabilityModalOpen = action.payload;
      if (!action.payload) {
        state.data.tariffRates = [];
      }
    },
    calculateSolbidRatesRequest(state, action) {
      state.isLoading = true;
    },
    calculateSolbidRatesSuccess(state, action) {
      state.isLoading = false;
      let id = state.solbidRates.solbidRatesForm.id;
      state.solbidRates.solbidRatesForm = action.payload;
      state.solbidRates.solbidRatesForm.id = id;
    },
    toggleIntakeForm(state) {
      state.isIntakeModalOpen = !state.isIntakeModalOpen;
    },
    submitIntakeRequest(state, action) {
      state.isLoading = true;
    },
    submitIntakeSuccess(state, action) {
      state.isLoading = false;
      //console.log(action.payload);
      state.monthlyRates = action.payload;
    },
    submitIntakeError(state, action) {
      state.isLoading = false;
    },
    regenerateSolbidRatesRequest(state) {
      state.isLoading = true;
    },
    regenerateSolbidRatesSuccess(state) {
      state.isLoading = true;
    },
  },
});

function loopAddressComponents(address_components: GoogleAddressComponent[]) {
  let zip: string = "";
  let foundPostalCode = false;
  for (let i = address_components.length - 1; i >= 0 && !foundPostalCode; i--) {
    const component = address_components[i];
    foundPostalCode = component.types.includes("postal_code");
    if (foundPostalCode) {
      zip = component.short_name;
    }
  }
  return zip;
}

function findFees(arr: TariffRate[]) {
  let fixedFee = 0;
  let supplyFee = 0;
  let deliveryFee = 0;
  let demandFee = 0;
  const supplyOptions: string[] = ["Supplier Services"];
  const deliveryOptions: string[] = ["Distribution Charge"];

  arr.forEach((item) => {
    if (item.quantityKey === "fixed") {
      item.categoryR = "Fixed Fee";
    }
    if (
      supplyOptions.includes(item.rateGroupName) &&
      item.quantityKey !== "fixed" &&
      item.transactionType !== "BUY"
    ) {
      item.categoryR = "Supply Fee";
    }
    if (
      deliveryOptions.includes(item.rateGroupName) &&
      item.quantityKey !== "fixed" &&
      item.transactionType !== "BUY"
    ) {
      item.categoryR = "Delivery Fee";
    }

    if (item.categoryId === 1) {
      fixedFee += item.rateAmount;
    }
    if (item.categoryId === 2) {
      deliveryFee += item.rateAmount;
    }
    if (item.categoryId === 3) {
      supplyFee += item.rateAmount;
    }
    if (item.categoryId === 4) {
      demandFee += item.rateAmount;
    }
  });
  return { fixedFee, supplyFee, deliveryFee, demandFee };
}

export const isNetMeteringLoading = (state: RootState) =>
  state.netMetering.isLoading;
export const getAllProviders = (state: RootState) =>
  state.netMetering.data.utility;
export const getAllBuildingTypes = (state: RootState) =>
  state.netMetering.data.buildingTypes;
export const getAllBuildingsDropdown = (state: RootState) =>
  state.netMetering.solbidRates.buildingsDropdown;
export const getUtilityRates = (state: RootState) =>
  state.netMetering.data.utilityRates;
export const formData = (state: RootState) => state.netMetering.data;
export const getZipCode = (state: RootState) => state.netMetering.data.zip;
export const getLocation = (state: RootState) =>
  state.netMetering.data.location;
export const getTariffRates = (state: RootState) =>
  state.netMetering.data.tariffRates;
export const getTariffsDropdown = (state: RootState) =>
  state.netMetering.solbidRates.tariffsDropdown;
export const getTariffsTable = (state: RootState) =>
  state.netMetering.solbidRates.tariffs;
export const getStatesDropdown = (state: RootState) =>
  state.netMetering.solbidRates.statesDropdown;
export const getUtilitiesDropdown = (state: RootState) =>
  state.netMetering.solbidRates.utilitiesDropdown;
export const getSelectedUtility = (state: RootState) =>
  state.netMetering.solbidRates.selectedUtility;
export const getIsModalOpen = (state: RootState) =>
  state.netMetering.solbidRates.isModalOpen;
export const getSelectedTariff = (state: RootState) =>
  state.netMetering.solbidRates.selectedTariff;
export const getSolbidRatesForm = (state: RootState) =>
  state.netMetering.solbidRates.solbidRatesForm;
export const getGenabilityModalState = (state: RootState) =>
  state.netMetering.isGenabilityModalOpen;
export const selectedState = (state: RootState) =>
  state.netMetering.solbidRates.selectedState;
export const selectIsIntakeModalOpen = (state: RootState) =>
  state.netMetering.isIntakeModalOpen;
export const selectMonthlyRates = (state: RootState) =>
  state.netMetering.monthlyRates;

export const {
  netMeteringRequest,
  netMeteringSuccess,
  netMeteringError,
  getUtilityRatesRequest,
  getUtilityRatesSuccess,
  getUtilityRateError,
  setBuildingTypeId,
  setTariffId,
  getTariffRateRequest,
  getTariffRateSuccess,
  getBuildingTypesRequest,
  getBuildingTypesSuccess,
  getEnergyIntensityRequest,
  getEnergyIntensitySuccess,
  setRateCategoryRequest,
  setRateCategorySuccess,
  setBuildingSqft,
  getMaxMonthlyUseRequest,
  getMaxMonthlyUseSuccess,
  getMaxDemandRequest,
  getMaxDemandSuccess,
  getOneUtilityRequest,
  getOneUtilitySuccess,
  processStreet,
  getUtilityRatesOnlineRequest,
  getUtilityRatesOnlineSuccess,
  getTariffsRequest,
  getTariffsSuccess,
  getTariffsError,
  getStatesDropdownRequest,
  getStatesDropdownSuccess,
  getStatesDropdownError,
  getUtilitiesDropdownRequest,
  getUtilitiesDropdownSuccess,
  getUtilitiesDropdownError,
  getOnlineTariffsRequest,
  getOnlineTariffsSuccess,
  getOnlineTariffsError,
  toggleTariffModal,
  getSolbidTariffRequest,
  getSolbidRatesRequest,
  getSolbidRatesSuccess,
  getSolbidRatesError,
  submitSolbidRatesRequest,
  submitSolbidRatesSuccess,
  submitSolbidRatesError,
  setPeakCategoryRequest,
  setPeakCategorySuccess,
  toggleTariffActiveRequest,
  toggleTariffActiveSuccess,
  toggleGenabilityModal,
  calculateSolbidRatesRequest,
  calculateSolbidRatesSuccess,
  toggleIntakeForm,
  submitIntakeRequest,
  submitIntakeSuccess,
  submitIntakeError,
  getTariffsDropdownSuccess,
  getTariffsDropdownError,
  regenerateSolbidRatesRequest,
  regenerateSolbidRatesSuccess,
  getTariffsWithErrorsRequest,
  getTariffsWithErrorsSuccess,
  getTariffsWithErrorsError,
} = netMeteringSlice.actions;

export default netMeteringSlice.reducer;
