import { useEffect } from "react";
import { Dialog, DialogBody, HTMLSelect } from "@blueprintjs/core";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getTariffRates,
  setRateCategoryRequest,
  setPeakCategoryRequest,
  getGenabilityModalState,
  toggleGenabilityModal, getTariffRateRequest,
} from "../NetMetering.slice";

export const TariffRatesDetails = () => {
  const dispatch = useAppDispatch();
  const tariffRates = useAppSelector(getTariffRates);
  const isOpen = useAppSelector(getGenabilityModalState);

  useEffect(() => {
    if (isOpen) {
      dispatch(getTariffRateRequest())
    }
  }, [dispatch, isOpen]);

  return (
    <Dialog
      title="Fees Details"
      icon="info-sign"
      style={{ width: "1200px" }}
      isOpen={isOpen}
      onClose={() => dispatch(toggleGenabilityModal(false))}
    >
      <DialogBody>
        <table className="bp4-html-table bp4-compact bp4-html-table-bordered bp4-html-table-striped">
          <thead>
            <tr>
              <th>Suggested</th>
              <th>Used In</th>
              <th>Peak</th>
              <th>Group Name</th>
              <th>Rate Name</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {tariffRates.map((item, index) => (
              <tr key={index}>
                <td>
                  <b>{item.categoryR}</b>
                </td>
                <td style={{ width: "160px" }}>
                  <HTMLSelect
                    name={"categoryId"}
                    defaultValue={item.categoryId}
                    style={{
                      backgroundColor: item.categoryId ? "#f6f7f9" : "#f5caca",
                    }}
                    options={[
                      { value: 0, label: "Not used" },
                      { value: 1, label: "Fixed Fee" },
                      { value: 2, label: "Delivery Fee" },
                      { value: 3, label: "Supply Fee" },
                      { value: 4, label: "Demand Fee" },
                    ]}
                    onChange={(event) => {
                      dispatch(
                        setRateCategoryRequest({
                          tariffRateId: item.tariffRateId,
                          categoryId: +event.currentTarget.value,
                        })
                      );
                    }}
                  />
                </td>
                <td style={{ width: "160px" }}>
                  <HTMLSelect
                    name={"peakId"}
                    defaultValue={item.peakId}
                    style={{
                      backgroundColor: item.peakId ? "#f6f7f9" : "#f5caca",
                    }}
                    options={[
                      { value: 0, label: "Not used" },
                      { value: 1, label: "On Peak" },
                      { value: 2, label: "Partial Peak" },
                      { value: 3, label: "Off Peak" },
                      { value: 4, label: "Summer On Peak" },
                      { value: 5, label: "Summer Partial Peak" },
                      { value: 6, label: "Summer Off Peak" },
                      { value: 7, label: "All Time" },
                    ]}
                    onChange={(event) => {
                      dispatch(
                        setPeakCategoryRequest({
                          tariffRateId: item.tariffRateId,
                          peakId: +event.currentTarget.value,
                        })
                      );
                    }}
                  />
                </td>
                <td>{item.rateGroupName}</td>
                <td>{item.rateName}</td>
                <td>{item.rateAmount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </DialogBody>
    </Dialog>
  );
};
