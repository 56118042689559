import styled from "styled-components";

const DragItem = styled.div`
  margin: 0 0 8px 0;
  background-color: ${(props: any) =>
    props.isDragging ? "lightgreen" : "white"};
  width: 100%;
  .bp4-dialog-header {
    height: 26px;
    min-height: 26px;
  }
  .bp4-dialog-body {
    padding: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border-bottom: 1px solid #4c525a;
  height: 23px;
  color: #f6f7f3;
  background-color: #383e47;
`;

const Body = styled.div`
  color: #ececec;
  padding: 0 8px 8px 8px;
  background-color: #404040;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

const Img = styled.div`
  background-color: #efefef;
`;

const Buttons = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Title = styled.h4`
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #4c525a;
  background-color: #383e47;
  color: #f6f7f9;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 29px;
`;

const HorizontalTitle = styled.h3`
  padding: 0;
  margin: 0;
  border-right: 1px solid lightgrey;
  background-color: aliceblue;
  writing-mode: vertical-lr;
  display: flex;
  justify-content: center;
`;

const ItemList = styled.div`
  padding: 10px;
  transition: background-color 0.2s ease;
  background-color: ${(props: any) =>
    props.isDraggingOver ? "#a1a1a1" : "none"};
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
`;

const ItemContainer = styled.div`
  height: 100%;
`;

const DragColumn = styled.div`
  .bp4-dialog-header {
    height: 32px;
    min-height: 32px;
  }
  .bp4-dialog-body {
    margin: 0;
  }
  .bp4-dialog-close-button {
    margin-right: -4px;
  }
`;

const CardDialog = styled.div`
  width: 100%;
`;

const TableFlexContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CardContainer = styled.div`
  margin: 50px auto;
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  flex-direction: column;
  flex-wrap: nowrap;

  .bp4-dialog {
    margin: 0 0 25px 0;
  }
  .edit-item-container {
    width: 100%;
    max-width: 900px;
  }
`;

const CardContainerHorizontal = styled.div`
  margin: 50px 0 0 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;

const CardContainerHorizontalOnSides = styled.div`
  margin: 37px 20px 0px;
  display: flex;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  justify-content: space-between;
`;

const FlexSplitViewContainer = styled.div`
  display: flex;
  width: 100%;

  justify-content: space-evenly;
  ${CardContainer} {
    width: 45%;
  }
  @media screen and (max-width: 1250px) {
    flex-direction: column;
    ${CardContainer} {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
    .bottom-container {
      margin-top: 20px;
    }
  }
`;

const AdminSelectionBox = styled.div`
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const NavBar = styled.header`
  background: #1f1f1f;
  border-bottom: 1px solid rgba(20, 29, 56, 0.15);
  position: relative;
  z-index: 10;
  display: block;
  height: 100px;
`;

const NavBarContainer = styled.header`
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  flex: 1 auto;
`;

const EditItemFormLeft = styled.div`
  width: 45%;
`;

const EditItemFormRight = styled.div`
  width: 55%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .bp4-form-group {
    padding-right: 20px;
  }
  @media screen and (max-width: 1250px) {
    flex-direction: column;
    align-items: center;
    ${EditItemFormLeft} {
      width: 95%;
    }
    ${EditItemFormRight} {
      width: 95%;
    }
  }
`;

const LeftFormGroup = styled.div`
  width: 50%;
`;
const RightFormGroup = styled.div`
  width: 50%;
`;

export const Styles = {
  DragItem,
  Header,
  Body,
  Img,
  Buttons,
  Title,
  ItemList,
  HorizontalTitle,
  ItemContainer,
  DragColumn,
  CardDialog,
  CardContainer,
  CardContainerHorizontal,
  AdminSelectionBox,
  NavBar,
  NavBarContainer,
  FlexSplitViewContainer,
  FormContainer,
  EditItemFormLeft,
  EditItemFormRight,
  TableFlexContainer,
  FormRow,
  CardContainerHorizontalOnSides,
  LeftFormGroup,
  RightFormGroup,
};
