import HTTP from "../../app/axios.interceptor";
import ExcelJS from "exceljs";
import { itemsColumns } from "./columns";

const getAllForExcel = async (): Promise<any[]> => {
  const response = await HTTP.get(`/items/get-for-excel`);
  return response.data;
};

const generateExcel = async (data: any): Promise<any> => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Inventory");
  sheet.columns = itemsColumns;

  data.forEach((rowData: any) => {
    sheet.addRow(rowData);
  });

  workbook.xlsx.writeBuffer().then(function (data: any) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "inventory.xlsx";
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};

export const excelServices = {
  getAllForExcel,
  generateExcel,
};
