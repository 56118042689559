import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
  NumericInput,
} from "@blueprintjs/core";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  formData,
  getAllBuildingTypes,
  getAllProviders,
  getOneUtilityRequest,
  getUtilityRates,
  getUtilityRatesRequest,
  isNetMeteringLoading,
  setBuildingTypeId,
  setBuildingSqft,
  setTariffId,
  toggleGenabilityModal,
} from "../NetMetering.slice";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { NetMeteringInterface } from "../NetMetering.initialState";
import { TariffRatesDetails } from "../dialogs/TariffRatesDetails";
import { Styles } from "../../styles/main.styled";

type Props = {};

const NetMeteringSchema = Yup.object().shape({
  // zip: Yup.string().required("Zip Code is required"),
  street: Yup.string().required("Street is required"),
  // use_type: Yup.object()
  //   .shape({
  //     label: Yup.string().required("Required"),
  //     value: Yup.string().required("Required"),
  //   })
  //   .typeError("Building Use Type is required"),
});
export const SubmittedForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isNetMeteringLoading);
  const allProviders = useAppSelector(getAllProviders);
  const formFields = useAppSelector(formData);
  const useTypeOptions = useAppSelector(getAllBuildingTypes);
  const utilityRates = useAppSelector(getUtilityRates);
  const [isUtilityDisabled, enableRates] = useState(false);
  const [isRateDisabled, enableUtility] = useState(false);

  const providersOptions: { value: string; label: string }[] = allProviders;

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    //register,
    //watch,
    //formState,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm<NetMeteringInterface>({
    resolver: yupResolver(NetMeteringSchema),
    defaultValues: formFields,
  });

  useEffect(() => {
    reset({ ...formFields });
  }, [reset, formFields]);

  const handleBuildingSqft = (e: any) => {
    setValue("buildingSqft", e.target.value);
    dispatch(setBuildingSqft(getValues("buildingSqft")));
  };

  const onSubmit = handleSubmit((data) => {
    //dispatch(netMeteringRequest(data));
    console.dir(data);
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="bp4-dialog-body">
          <Styles.FormRow>
            <Controller
              control={control}
              name="street"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormGroup
                  label="Street"
                  labelFor={name}
                  labelInfo={"*"}
                  style={{ width: "280px", marginRight: "16px" }}
                >
                  <InputGroup
                    id={name}
                    placeholder="Street Name"
                    type={"text"}
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    inputRef={ref}
                    readOnly={false}
                  />
                  <p className={"validationMessage"}>
                    {errors.street?.message}
                  </p>
                </FormGroup>
              )}
            />

            <Controller
              control={control}
              name="city"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormGroup
                  label="City"
                  labelFor={name}
                  labelInfo={"*"}
                  style={{ width: "160px", marginRight: "16px" }}
                >
                  <InputGroup
                    id={name}
                    placeholder="City"
                    type={"text"}
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    inputRef={ref}
                    readOnly={false}
                  />
                  <p className={"validationMessage"}>{errors.city?.message}</p>
                </FormGroup>
              )}
            />
            <Controller
              control={control}
              name="stateAbbr"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormGroup
                  label="State Code"
                  labelFor={name}
                  labelInfo={"*"}
                  style={{ width: "100px", marginRight: "16px" }}
                >
                  <InputGroup
                    id={name}
                    placeholder="State"
                    type={"text"}
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    inputRef={ref}
                    readOnly={false}
                  />
                  <p className={"validationMessage"}>
                    {errors.stateAbbr?.message}
                  </p>
                </FormGroup>
              )}
            />
          </Styles.FormRow>
          <Styles.FormRow>
            <Controller
              control={control}
              name="state"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormGroup
                  label="State"
                  labelFor={name}
                  labelInfo={"*"}
                  style={{ width: "160px", marginRight: "16px" }}
                >
                  <InputGroup
                    id={name}
                    placeholder="State"
                    type={"text"}
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    inputRef={ref}
                    readOnly={false}
                  />
                  <p className={"validationMessage"}>{errors.state?.message}</p>
                </FormGroup>
              )}
            />

            <Controller
              control={control}
              name="countyFullName"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormGroup
                  label="County Full Name"
                  labelFor={name}
                  labelInfo={"*"}
                  style={{ width: "180px", marginRight: "16px" }}
                >
                  <InputGroup
                    id={name}
                    placeholder="County Full Name"
                    type={"text"}
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    inputRef={ref}
                    readOnly={false}
                  />
                  <p className={"validationMessage"}>
                    {errors.countyFullName?.message}
                  </p>
                </FormGroup>
              )}
            />

            <Controller
              control={control}
              name="countyName"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormGroup
                  label="County Name"
                  labelFor={name}
                  labelInfo={"*"}
                  style={{ width: "180px" }}
                >
                  <InputGroup
                    id={name}
                    placeholder="County Name"
                    type={"text"}
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    inputRef={ref}
                    readOnly={false}
                  />
                  <p className={"validationMessage"}>
                    {errors.countyName?.message}
                  </p>
                </FormGroup>
              )}
            />
          </Styles.FormRow>
          <Styles.FormRow>
            <Controller
              control={control}
              name="countyType"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormGroup
                  label="County Type"
                  labelFor={name}
                  labelInfo={"*"}
                  style={{ width: "160px", marginRight: "16px" }}
                >
                  <InputGroup
                    id={name}
                    placeholder="County Type"
                    type={"text"}
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    inputRef={ref}
                    readOnly={false}
                  />
                  <p className={"validationMessage"}>
                    {errors.countyType?.message}
                  </p>
                </FormGroup>
              )}
            />
            <Controller
              control={control}
              name="taxRate"
              rules={{ required: true }}
              render={({ field: { onChange, name, onBlur, value, ref } }) => (
                <FormGroup
                  helperText={
                    errors.taxRate && <span>This field is required</span>
                  }
                  label="Tax Rate"
                  labelFor={name}
                  labelInfo="*"
                  intent={errors.taxRate && Intent.DANGER}
                >
                  <NumericInput
                    style={{ width: "80px" }}
                    id={name}
                    name={name}
                    onBlur={onBlur}
                    onValueChange={onChange}
                    value={value}
                    minorStepSize={0.01}
                    stepSize={1}
                    asyncControl={true}
                    min={0.0}
                    max={30.0}
                    placeholder={name}
                    inputRef={ref}
                    intent={errors.taxRate && Intent.DANGER}
                  />
                </FormGroup>
              )}
            />
            <Controller
              control={control}
              name="buildingSqft"
              rules={{ required: false }}
              render={({ field: { onChange, name, onBlur, value, ref } }) => (
                <FormGroup
                  helperText={
                    errors.buildingSqft && <span>This field is required</span>
                  }
                  label="Building Sqft."
                  labelFor={name}
                  labelInfo=""
                  intent={errors.buildingSqft && Intent.DANGER}
                  style={{ marginLeft: "16px" }}
                >
                  <NumericInput
                    style={{ width: "120px" }}
                    id={name}
                    name={name}
                    onBlur={handleBuildingSqft}
                    onValueChange={onChange}
                    value={value}
                    minorStepSize={0.01}
                    stepSize={1}
                    asyncControl={true}
                    min={0.0}
                    max={500000}
                    placeholder={name}
                    inputRef={ref}
                    intent={errors.buildingSqft && Intent.DANGER}
                  />
                </FormGroup>
              )}
            />

            <Controller
              control={control}
              name="annualKwh"
              rules={{ required: false }}
              render={({ field: { onChange, name, onBlur, value, ref } }) => (
                <FormGroup
                  helperText={
                    errors.annualKwh && <span>This field is required</span>
                  }
                  label="Annual kWH"
                  style={{ marginLeft: "16px" }}
                  labelFor={name}
                  labelInfo=""
                  intent={errors.annualKwh && Intent.DANGER}
                >
                  <NumericInput
                    style={{ width: "110px" }}
                    id={name}
                    name={name}
                    onBlur={onBlur}
                    onValueChange={onChange}
                    value={value}
                    minorStepSize={0.01}
                    stepSize={1}
                    asyncControl={true}
                    min={0.0}
                    placeholder={name}
                    inputRef={ref}
                    intent={errors.annualKwh && Intent.DANGER}
                  />
                </FormGroup>
              )}
            />
          </Styles.FormRow>

          <Styles.FormRow>
            <Controller
              control={control}
              name="climate"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormGroup
                  label="Climate"
                  labelFor={name}
                  labelInfo={"*"}
                  style={{ width: "120px", marginRight: "16px" }}
                >
                  <InputGroup
                    id={name}
                    placeholder="Climate"
                    type={"text"}
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    inputRef={ref}
                    readOnly={false}
                  />
                  <p className={"validationMessage"}>
                    {errors.climate?.message}
                  </p>
                </FormGroup>
              )}
            />

            <Controller
              control={control}
              name="climateZone"
              rules={{ required: true }}
              render={({ field: { onChange, name, onBlur, value, ref } }) => (
                <FormGroup
                  helperText={
                    errors.taxRate && <span>This field is required</span>
                  }
                  label="Climate Zone"
                  labelFor={name}
                  labelInfo="*"
                  intent={errors.climateZone && Intent.DANGER}
                >
                  <NumericInput
                    style={{ width: "90px" }}
                    id={name}
                    name={name}
                    onBlur={onBlur}
                    onValueChange={onChange}
                    value={value}
                    minorStepSize={0.01}
                    stepSize={1}
                    asyncControl={true}
                    min={1}
                    max={8}
                    placeholder={name}
                    inputRef={ref}
                    intent={errors.climateZone && Intent.DANGER}
                  />
                </FormGroup>
              )}
            />

            <Controller
              control={control}
              name="humidity"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormGroup
                  label="Humidity"
                  labelFor={name}
                  labelInfo={"*"}
                  style={{ width: "70px", marginLeft: "16px" }}
                >
                  <InputGroup
                    id={name}
                    placeholder="Humidity"
                    type={"text"}
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    inputRef={ref}
                    readOnly={false}
                  />
                  <p className={"validationMessage"}>
                    {errors.humidity?.message}
                  </p>
                </FormGroup>
              )}
            />

            <Controller
              control={control}
              name="maxMonthlyUse"
              rules={{ required: true }}
              render={({ field: { onChange, name, onBlur, value, ref } }) => (
                <FormGroup
                  helperText={
                    errors.maxMonthlyUse && <span>This field is required</span>
                  }
                  label="Max Monthly Use kWh"
                  style={{ marginLeft: "16px" }}
                  labelFor={name}
                  labelInfo="*"
                  intent={errors.maxMonthlyUse && Intent.DANGER}
                >
                  <NumericInput
                    style={{ width: "120px" }}
                    id={name}
                    name={name}
                    onBlur={onBlur}
                    onValueChange={onChange}
                    value={value}
                    minorStepSize={0.01}
                    stepSize={1}
                    asyncControl={true}
                    min={0.0}
                    placeholder={name}
                    inputRef={ref}
                    intent={errors.maxMonthlyUse && Intent.DANGER}
                  />
                </FormGroup>
              )}
            />

            <Controller
              control={control}
              name="maxDemand"
              rules={{ required: true }}
              render={({ field: { onChange, name, onBlur, value, ref } }) => (
                <FormGroup
                  helperText={
                    errors.maxDemand && <span>This field is required</span>
                  }
                  label="Max Demand kW"
                  style={{ marginLeft: "16px" }}
                  labelFor={name}
                  labelInfo="*"
                  intent={errors.maxDemand && Intent.DANGER}
                >
                  <NumericInput
                    style={{ width: "100px" }}
                    id={name}
                    name={name}
                    onBlur={onBlur}
                    onValueChange={onChange}
                    value={value}
                    minorStepSize={0.01}
                    stepSize={1}
                    asyncControl={true}
                    min={0.0}
                    placeholder={name}
                    inputRef={ref}
                    intent={errors.maxDemand && Intent.DANGER}
                  />
                </FormGroup>
              )}
            />
          </Styles.FormRow>

          {providersOptions.length > 0 && (
            <>
              <Styles.FormRow>
                <Controller
                  control={control}
                  name="lseId"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, name, onBlur, value, ref },
                  }) => (
                    <FormGroup
                      helperText={
                        errors.lseId && <span>This field is required</span>
                      }
                      label="Utility Provider"
                      labelFor={name}
                      labelInfo="*"
                      disabled={isUtilityDisabled}
                      intent={errors.lseId && Intent.DANGER}
                    >
                      <HTMLSelect
                        name={name}
                        onBlur={onBlur}
                        onChange={(event) => {
                          onChange(event);
                          dispatch(
                            getUtilityRatesRequest(event.currentTarget.value)
                          );
                          dispatch(
                            getOneUtilityRequest(event.currentTarget.value)
                          );
                        }}
                        //placeholder={name}
                        defaultValue={0}
                        disabled={isUtilityDisabled}
                        style={{ width: "100%" }}
                        options={[
                          { value: 0, label: "Select...", disabled: true },
                          ...providersOptions,
                        ]}
                      />
                    </FormGroup>
                  )}
                />
                {formFields.lseId !== undefined && formFields.lseId > 0 && (
                  <Controller
                    control={control}
                    name="buildingUseType"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, name, onBlur, value, ref },
                    }) => (
                      <FormGroup
                        helperText={
                          errors.buildingUseType && (
                            <span>This field is required</span>
                          )
                        }
                        label="Building Use Type"
                        labelFor={name}
                        labelInfo="*"
                        style={{ marginLeft: "16px" }}
                        intent={errors.buildingUseType && Intent.DANGER}
                      >
                        <HTMLSelect
                          name={name}
                          onBlur={onBlur}
                          onChange={(event) => {
                            onChange(event);
                            dispatch(
                              setBuildingTypeId(event.currentTarget.value)
                            );
                          }}
                          //placeholder={name}
                          defaultValue={0}
                          style={{ width: "200px" }}
                          options={[
                            { value: 0, label: "Select...", disabled: true },
                            ...useTypeOptions,
                          ]}
                        />
                      </FormGroup>
                    )}
                  />
                )}
              </Styles.FormRow>
              <Styles.FormRow>
                <Controller
                  control={control}
                  name="solBidProgramType"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, name, onBlur, value, ref },
                  }) => (
                    <FormGroup
                      helperText={
                        errors.solBidProgramType && (
                          <span>This field is required</span>
                        )
                      }
                      label="Solar Program Type"
                      labelFor={name}
                      labelInfo="*"
                      disabled={isUtilityDisabled}
                      intent={errors.solBidProgramType && Intent.DANGER}
                      style={{ marginRight: "16px" }}
                    >
                      <HTMLSelect
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        //placeholder={name}
                        value={formFields.solBidProgramType}
                        disabled={isUtilityDisabled}
                        style={{ width: "160px" }}
                        options={[
                          { value: 0, label: "Select...", disabled: false },
                          { value: 1, label: "1", disabled: false },
                          { value: 2, label: "2", disabled: false },
                        ]}
                      />
                    </FormGroup>
                  )}
                />

                <Controller
                  control={control}
                  name="solarRateType"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, name, onBlur, value, ref },
                  }) => (
                    <FormGroup
                      helperText={
                        errors.solarRateType && (
                          <span>This field is required</span>
                        )
                      }
                      label="Solar Rate Type"
                      labelFor={name}
                      labelInfo="*"
                      disabled={isUtilityDisabled}
                      intent={errors.solarRateType && Intent.DANGER}
                      style={{ marginRight: "16px" }}
                    >
                      <HTMLSelect
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        //placeholder={name}
                        value={formFields.solarRateType}
                        disabled={isUtilityDisabled}
                        style={{ width: "160px" }}
                        options={[
                          { value: 0, label: "Select...", disabled: false },
                          { value: 1, label: "1", disabled: false },
                          { value: 2, label: "2", disabled: false },
                        ]}
                      />
                    </FormGroup>
                  )}
                />

                <Controller
                  control={control}
                  name="solarRate"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, name, onBlur, value, ref },
                  }) => (
                    <FormGroup
                      helperText={
                        errors.solarRate && <span>This field is required</span>
                      }
                      label="Solar Rate"
                      style={{ marginLeft: "16px" }}
                      labelFor={name}
                      labelInfo="*"
                      intent={errors.solarRate && Intent.DANGER}
                    >
                      <NumericInput
                        style={{ width: "100px" }}
                        id={name}
                        name={name}
                        onBlur={onBlur}
                        onValueChange={onChange}
                        value={formFields.solarRate}
                        minorStepSize={0.01}
                        stepSize={1}
                        asyncControl={true}
                        min={0.0}
                        placeholder={name}
                        inputRef={ref}
                        intent={errors.solarRate && Intent.DANGER}
                      />
                    </FormGroup>
                  )}
                />

                <Controller
                  control={control}
                  name="solarProgramCap"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, name, onBlur, value, ref },
                  }) => (
                    <FormGroup
                      helperText={
                        errors.solarProgramCap && (
                          <span>This field is required</span>
                        )
                      }
                      label="System Size Cap"
                      style={{ marginLeft: "16px" }}
                      labelFor={name}
                      labelInfo="*"
                      intent={errors.solarProgramCap && Intent.DANGER}
                    >
                      <NumericInput
                        style={{ width: "100px" }}
                        id={name}
                        name={name}
                        onBlur={onBlur}
                        onValueChange={onChange}
                        value={formFields.solarProgramCap}
                        minorStepSize={0.01}
                        stepSize={1}
                        asyncControl={true}
                        min={0.0}
                        placeholder={name}
                        inputRef={ref}
                        intent={errors.solarProgramCap && Intent.DANGER}
                      />
                    </FormGroup>
                  )}
                />
              </Styles.FormRow>
            </>
          )}

          {formFields.buildingUseType !== "" && (
            <Styles.FormRow>
              <Controller
                control={control}
                name="tariffId"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    helperText={
                      errors.tariffId && <span>This field is required</span>
                    }
                    label="Utility Rates"
                    labelFor={name}
                    labelInfo="*"
                    disabled={isRateDisabled}
                    intent={errors.tariffId && Intent.DANGER}
                  >
                    <HTMLSelect
                      name={name}
                      onBlur={onBlur}
                      onChange={(event) => {
                        onChange(event);
                        dispatch(setTariffId(event.currentTarget.value));
                      }}
                      //placeholder={name}
                      defaultValue={0}
                      style={{ width: "100%" }}
                      disabled={isRateDisabled}
                      options={[
                        { value: 0, label: "Select...", disabled: true },
                        ...utilityRates,
                      ]}
                    />
                  </FormGroup>
                )}
              />

              <Controller
                control={control}
                name="energyIntensity"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    helperText={
                      errors.energyIntensity && (
                        <span>This field is required</span>
                      )
                    }
                    label="Energy Intensity"
                    style={{ marginLeft: "16px" }}
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.energyIntensity && Intent.DANGER}
                  >
                    <NumericInput
                      style={{ width: "110px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      max={500.0}
                      placeholder={name}
                      inputRef={ref}
                      intent={errors.energyIntensity && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
          )}
          {formFields.tariffRates.length > 0 && (
            <Styles.FormRow>
              <Controller
                control={control}
                name="fixedFee"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    helperText={
                      errors.fixedFee && <span>This field is required</span>
                    }
                    label="Fixed Fee"
                    style={{ marginRight: "16px" }}
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.fixedFee && Intent.DANGER}
                  >
                    <NumericInput
                      style={{ width: "110px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      max={500.0}
                      placeholder={name}
                      inputRef={ref}
                      intent={errors.fixedFee && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="deliveryFee"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    helperText={
                      errors.deliveryFee && <span>This field is required</span>
                    }
                    label="Delivery Fee"
                    style={{ marginRight: "16px" }}
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.deliveryFee && Intent.DANGER}
                  >
                    <NumericInput
                      style={{ width: "110px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      max={30.0}
                      placeholder={name}
                      inputRef={ref}
                      intent={errors.deliveryFee && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="supplyFee"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    helperText={
                      errors.supplyFee && <span>This field is required</span>
                    }
                    label="Supply Fee"
                    style={{ marginRight: "16px" }}
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.supplyFee && Intent.DANGER}
                  >
                    <NumericInput
                      style={{ width: "110px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      max={30.0}
                      placeholder={name}
                      inputRef={ref}
                      intent={errors.supplyFee && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="demandFee"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    helperText={
                      errors.demandFee && <span>This field is required</span>
                    }
                    label="Demand Fee"
                    style={{ marginRight: "16px" }}
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.demandFee && Intent.DANGER}
                  >
                    <NumericInput
                      style={{ width: "110px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      max={30.0}
                      placeholder={name}
                      inputRef={ref}
                      intent={errors.demandFee && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />

              <div style={{ marginTop: "25px" }}>
                <Button
                  icon={"help"}
                  onClick={() => dispatch(toggleGenabilityModal(true))}
                />
                <TariffRatesDetails />
              </div>
            </Styles.FormRow>
          )}
        </div>
        <div className="bp4-dialog-footer">
          <div className="bp4-dialog-footer-actions">
            <Button
              intent={"primary"}
              text={"Click Me"}
              loading={isLoading}
              type={"submit"}
            />
          </div>
        </div>
      </form>
    </>
  );
};
