import React from "react";
import { HotkeysProvider } from "@blueprintjs/core";
import { Cell, Column, SelectionModes, Table2 } from "@blueprintjs/table";
import { useAppSelector } from "../../app/hooks";
import { selectMonthlyRates } from "../NetMetering.slice";

export const ExcelLikeTable = () => {
  const data = useAppSelector(selectMonthlyRates);
  const months: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const renderCell = (rowIndex: number) => {
    return <Cell>{data.load.loadsMonthly[rowIndex]}</Cell>;
  };

  return (
    <div style={{ margin: "80px 20px 0 20px" }}>
      <HotkeysProvider>
        <Table2
          numRows={months.length + 1}
          selectionModes={SelectionModes.COLUMNS_AND_CELLS}
        >
          <Column name="Total (kWh)" cellRenderer={renderCell} />
          <Column />
          <Column />
          <Column />
        </Table2>
      </HotkeysProvider>
    </div>
  );
};
