import { combineReducers } from "redux";
import authReducer from "../pages/auth/_services/Auth.slice";
import appReducer from "../app/app.slice";
import netMeteringReducer from "../net-metering/NetMetering.slice";
import socketReducer from "../web-sockets/socketSlice";
import solarProgramsReducer from "../net-metering/solar-programs/SolarPrograms.slice";

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  socket: socketReducer,
  netMetering: netMeteringReducer,
  //theia: theiaSlice,
  solarPrograms: solarProgramsReducer,
});

export default rootReducer;
