import {
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from "@redux-saga/core/effects";
import {
  getAllByStateError,
  getAllByStateRequest,
  getAllByStateSuccess,
  updateSolarProgramRequest,
  updateSolarProgramSuccess,
} from "./SolarPrograms.slice";
import { solarProgramsServices } from "./SolarPrograms.services";

export function* fetchAllByStateRequest({
  payload,
}: any): Generator<CallEffect | PutEffect | ForkEffect> {
  try {
    const response = yield call(
      solarProgramsServices.getAllSolarRatesByState,
      payload
    );
    yield put(getAllByStateSuccess(response));
  } catch (error: any) {
    yield put(getAllByStateError("Error: 032743"));
  }
}

export function* fetchUpdateSolarProgram({
  payload,
}: any): Generator<CallEffect | PutEffect | ForkEffect> {
  try {
    let postData = { ...payload };
    delete postData["id"];
    delete postData["lse"];
    delete postData["lseId"];
    delete postData["stateId"];
    delete postData["localId"];

    yield call(solarProgramsServices.updateSolarRate, payload.id, postData);
    yield put(updateSolarProgramSuccess(payload));
  } catch (error: any) {}
}

export function* watchGetAllSolarProgramsByState() {
  yield takeLatest(getAllByStateRequest, fetchAllByStateRequest);
}

export function* watchUpdateSolarProgram() {
  yield takeLatest(updateSolarProgramRequest, fetchUpdateSolarProgram);
}
