export const itemsColumns = [
  { header: "Id", key: "id", width: 4 },
  { header: "New Solbid ID", key: "solbid_id_new", width: 12 },
  { header: "Category", key: "categoryName", width: 15 },
  { header: "Device Name", key: "device", width: 40 },
  { header: "Model", key: "model", width: 20 },
  { header: "Location", key: "locationName", width: 20 },
  { header: "Date of Issue", key: "dateLastIssue", width: 28 },
  { header: "Status", key: "status", width: 10 },
];
