import * as React from "react";
import { HTMLTable } from "@blueprintjs/core";
import { SolarProgram } from "./SolarPrograms.initialState";
import { useAppDispatch } from "../../app/hooks";
import { setSelected } from "./SolarPrograms.slice";

type Props = {
  isLoading: boolean;
  solarPrograms: SolarProgram[];
};
export const SolarProgramsTable = ({ isLoading, solarPrograms }: Props) => {
  const dispatch = useAppDispatch();
  return (
    <div>
      <HTMLTable interactive={true} bordered={true} striped={true}>
        <thead>
          <tr>
            <th style={{ width: "90px" }}>lseCode</th>
            <th>lseName</th>
            <th style={{ width: "100px" }}>solarProgramType</th>
            <th style={{ width: "100px" }}>solarRateType</th>
            <th style={{ width: "100px" }}>solarRate</th>
          </tr>
        </thead>
        <tbody>
          {solarPrograms && solarPrograms.length > 0 ? (
            solarPrograms.map((row, index) => (
              <tr key={index} onClick={() => dispatch(setSelected(row))}>
                <td>{row.lse?.lseCode}</td>
                <td>{row.lse?.name}</td>
                <td>{row.solarProgramType}</td>
                <td>{row.solarRateType}</td>
                <td>{row.solarRate}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} style={{ textAlign: "center" }}>
                {isLoading ? "Loading..." : "NO DATA"}
              </td>
            </tr>
          )}
        </tbody>
      </HTMLTable>
    </div>
  );
};
