import * as React from "react";
import { Card, Elevation } from "@blueprintjs/core";
import { Styles } from "../../styles/main.styled";
import { CardHeader } from "../../components/Card/CardHeader";
import { SolarProgramsTable } from "./SolarPrograms.table";
import { useAppSelector } from "../../app/hooks";
import { getIsLoading, getSolarPrograms } from "./SolarPrograms.slice";
import { SolarProgramsStatesDropdown } from "./SolarPrograms.statesDropdown";
import { SolarProgramsDialog } from "./SolarPrograms.dialog";

export const SolarProgramsView = () => {
  const solarPrograms = useAppSelector(getSolarPrograms);
  const isLoading = useAppSelector(getIsLoading);

  return (
    <>
      <br />
      <br />
      <Styles.CardContainer>
        <Card elevation={Elevation.TWO} style={{ padding: "0", width: "94%" }}>
          <CardHeader
            leftIcon={"helper-management"}
            title={"Solar Programs"}
            content={<SolarProgramsStatesDropdown />}
          />
          <SolarProgramsTable
            isLoading={isLoading}
            solarPrograms={solarPrograms}
          />
        </Card>
      </Styles.CardContainer>
      <SolarProgramsDialog />
    </>
  );
};
