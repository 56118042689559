import axios from "axios";
import rootVars from "../../app/root.vars";
import { axiosNemConfig } from "../../app/axios.interceptor";

const getUtilityProviders = async (stateId: string) => {
  let ret = null;
  try {
    await axios
      .get(`${rootVars.nemBaseURL}/states/providers/${stateId}`, axiosNemConfig)
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

export const utilityProvidersDropdownServices = {
  getUtilityProviders,
};
