import io, { Socket } from "socket.io-client";
import {
  call,
  fork,
  put,
  race,
  take,
  takeLatest,
} from "@redux-saga/core/effects";
import { initializeSocket, startChannelFailure } from "./socketSlice";
import * as roomsSaga from "./rooms.saga";
import { chatEvents } from "./socketService";
import rootVars from "../app/root.vars";
import { logoutSuccess } from "../pages/auth/_services/Auth.slice";
import { store } from "../app/store";

function connection() {
  return io(rootVars.socketURL, {
    extraHeaders: {
      Authorization: store.getState().auth.session.token,
    },
  });
}

function* readEvents(socket: Socket) {
  const channel: Socket = yield call(chatEvents, socket);
  try {
    while (true) {
      const { logout, task } = yield race({
        logout: take(logoutSuccess),
        // @ts-ignore
        task: take(channel),
      });
      if (logout) {
        channel.close();
      } else {
        yield put(task);
      }
    }
  } catch (error: any) {
    yield put(startChannelFailure(error));
  } finally {
    channel.close();
    socket.disconnect();
  }
}

function* workerSocketFlow() {
  // const socket: Socket = yield call(connection);
  // yield fork(readEvents, socket);
  // yield fork(roomsSaga.joinRoom, socket);
  // yield fork(roomsSaga.leaveRoom, socket);
}

export function* watchSocketInitSaga() {
  yield takeLatest(initializeSocket, workerSocketFlow);
}
