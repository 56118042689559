// @flow
import * as React from "react";

type Props = {};
export const NotFound = (props: Props) => {
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <div style={{ fontSize: "50px" }}>Not Found</div>
    </div>
  );
};
