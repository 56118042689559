import React from "react";
import { Button, Card, Elevation, HTMLTable } from "@blueprintjs/core";
import { CardHeader } from "../../components/Card/CardHeader";
import { Styles } from "../../styles/main.styled";
import { IntakeForm } from "./Intake.form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectMonthlyRates, toggleIntakeForm } from "../NetMetering.slice";
import { RatesComponent } from "./Rates.component";

export const MonthlyOutputs = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectMonthlyRates);
  const months: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const headerStyle = {
    boxShadow: "inset 1px 1px 0 0 rgba(17, 20, 24, 0.15)",
  };

  const makeTotal = (arr: number[]) => {
    return arr.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
  };

  return (
    <div>
      <br />
      <br />
      <Styles.CardContainerHorizontalOnSides>
        <Button
          icon="duplicate"
          text={"Intake Form"}
          intent={"primary"}
          style={{ height: "30px" }}
          onClick={() => dispatch(toggleIntakeForm())}
        />
        <RatesComponent />
      </Styles.CardContainerHorizontalOnSides>
      <Styles.CardContainer style={{ margin: "20px" }}>
        <Card
          elevation={Elevation.TWO}
          style={{ padding: "0", overflowX: "auto", maxWidth: "100%" }}
        >
          <CardHeader
            leftIcon={"helper-management"}
            title={"Monthly Outputs"}
          />
          <HTMLTable
            interactive={true}
            bordered={true}
            striped={true}
            style={{ padding: "0", minWidth: "100%" }}
          >
            <thead>
              <tr>
                <th></th>
                <th
                  colSpan={8}
                  style={{
                    textAlign: "center",
                  }}
                >
                  Customer Load (kWh) and Time Of Use (TOU) Periods
                </th>
                <th
                  colSpan={7}
                  style={{
                    textAlign: "center",
                  }}
                >
                  PV/Solar Generation (kWh) and Time Of Use (TOU) Periods
                </th>
                <th
                  colSpan={9}
                  style={{
                    textAlign: "center",
                  }}
                >
                  Net Excess
                </th>
                <th colSpan={5}></th>
              </tr>
              <tr>
                <th style={{ width: "20px" }}>m</th>
                <th style={headerStyle}>Total (kWh)</th>
                <th style={headerStyle}>OnPeak</th>
                <th style={headerStyle}>PartPeak</th>
                <th style={headerStyle}>OffPeak</th>
                <th style={headerStyle}>SumOnPeak</th>
                <th style={headerStyle}>SumPartPeak</th>
                <th style={headerStyle}>SumOffPeak</th>
                <th style={headerStyle}>
                  Customer Utility
                  <br />
                  Bill Pre-Solar
                </th>
                <th style={headerStyle}>
                  AC PV System
                  <br />
                  Generation (kWh)
                </th>
                <th style={headerStyle}>OnPeak</th>
                <th style={headerStyle}>PartPeak</th>
                <th style={headerStyle}>OffPeak</th>
                <th style={headerStyle}>SumOnPeak</th>
                <th style={headerStyle}>SumPartPeak</th>
                <th style={headerStyle}>SumOffPeak</th>
                <th style={headerStyle}>NEM Monthly Net Excess</th>
                <th style={headerStyle}>OnPeak</th>
                <th style={headerStyle}>PartPeak</th>
                <th style={headerStyle}>OffPeak</th>
                <th style={headerStyle}>SumOnPeak</th>
                <th style={headerStyle}>SumPartPeak</th>
                <th style={headerStyle}>SumOffPeak</th>
                <th style={headerStyle}>SumMonth</th>
                <th style={headerStyle}>CostMonth</th>
                <th style={headerStyle}>
                  Solar Utility Bill Reduction Pre-Tax
                </th>
                <th style={headerStyle}>
                  Solar Utility Bill Demand Reduction Pre Tax
                </th>
                <th style={headerStyle}>
                  Total Utility Bill Reduction from Solar Pre Tax
                </th>
                <th style={headerStyle}>
                  Total Utility Bill Reduction from Solar Post Tax
                </th>
                <th style={headerStyle}>Customer Utility Bill Post Solar</th>
              </tr>
            </thead>
            <tbody>
              {months.map((m) => (
                <tr key={m}>
                  <td>{m + 1}</td>
                  <td>{data.load.loadsMonthly[m]}</td>
                  <td>{data.load.onPeak[m]}</td>
                  <td>{data.load.partialPeak[m]}</td>
                  <td>{data.load.offPeak[m]}</td>
                  <td>{data.load.summerOnPeak[m]}</td>
                  <td>{data.load.summerPartialPeak[m]}</td>
                  <td>{data.load.summerOffPeak[m]}</td>
                  <td>
                    {data.load.customerCost[m]
                      ? data.load.customerCost[m].toFixed(2)
                      : null}
                  </td>
                  <td>{data.solar.acMonthly[m]}</td>
                  <td>{data.solar.onPeak[m]}</td>
                  <td>{data.solar.partialPeak[m]}</td>
                  <td>{data.solar.offPeak[m]}</td>
                  <td>{data.solar.summerOnPeak[m]}</td>
                  <td>{data.solar.summerPartialPeak[m]}</td>
                  <td>{data.solar.summerOffPeak[m]}</td>
                  <td>{data.netExcess.monthlyNetExcess[m]}</td>
                  <td>{data.netExcess.onPeak[m]}</td>
                  <td>{data.netExcess.partialPeak[m]}</td>
                  <td>{data.netExcess.offPeak[m]}</td>
                  <td>{data.netExcess.summerOnPeak[m]}</td>
                  <td>{data.netExcess.summerPartialPeak[m]}</td>
                  <td>{data.netExcess.summerOffPeak[m]}</td>
                  <td>{data.netExcess.monthlyExcessPower[m]}</td>
                  <td>
                    {data.netExcess.monthlyExcessCost[m]
                      ? data.netExcess.monthlyExcessCost[m].toFixed(2)
                      : 0}
                  </td>
                  <td>
                    {data.solarUtilityBillReductionPreTax[m]
                      ? data.solarUtilityBillReductionPreTax[m].toFixed(2)
                      : 0}
                  </td>
                  <td>
                    {data.solarUtilityBillDemandReductionPreTax[m]
                      ? data.solarUtilityBillDemandReductionPreTax[m].toFixed(2)
                      : 0}
                  </td>
                  <td>
                    {data.totalUtilityBillReductionFromSolarPreTax[m]
                      ? data.totalUtilityBillReductionFromSolarPreTax[
                          m
                        ].toFixed(2)
                      : 0}
                  </td>
                  <td>
                    {data.totalUtilityBillReductionFromSolarPostTax[m]
                      ? data.totalUtilityBillReductionFromSolarPostTax[
                          m
                        ].toFixed(2)
                      : 0}
                  </td>
                  <td>
                    {data.customerUtilityBillPostSolar[m]
                      ? data.customerUtilityBillPostSolar[m].toFixed(2)
                      : 0}
                  </td>
                </tr>
              ))}
              <tr style={{ backgroundColor: "#d7d7d7" }}>
                <td>
                  <b>tot:</b>
                </td>
                <td>
                  <b>{makeTotal(data.load.loadsMonthly)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.load.onPeak)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.load.partialPeak)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.load.offPeak)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.load.summerOnPeak)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.load.summerPartialPeak)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.load.summerOffPeak)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.load.customerCost).toFixed(2)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.solar.acMonthly)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.solar.onPeak)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.solar.partialPeak)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.solar.offPeak)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.solar.summerOnPeak)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.solar.summerPartialPeak)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.solar.summerOffPeak)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.netExcess.monthlyNetExcess)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.netExcess.onPeak)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.netExcess.partialPeak)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.netExcess.offPeak)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.netExcess.summerOnPeak)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.netExcess.summerPartialPeak)}</b>
                </td>
                <td>
                  <b>{makeTotal(data.netExcess.summerOffPeak)}</b>
                </td>
                <td>
                  <b>
                    {makeTotal(data.netExcess.monthlyExcessPower).toFixed(2)}
                  </b>
                </td>
                <td>
                  <b>
                    {makeTotal(data.netExcess.monthlyExcessCost).toFixed(2)}
                  </b>
                </td>
                <td>
                  <b>
                    {makeTotal(data.solarUtilityBillReductionPreTax).toFixed(2)}
                  </b>
                </td>
                <td>
                  <b>
                    {makeTotal(
                      data.solarUtilityBillDemandReductionPreTax
                    ).toFixed(2)}
                  </b>
                </td>
                <td>
                  <b>
                    {makeTotal(
                      data.totalUtilityBillReductionFromSolarPreTax
                    ).toFixed(2)}
                  </b>
                </td>
                <td>
                  <b>
                    {makeTotal(
                      data.totalUtilityBillReductionFromSolarPostTax
                    ).toFixed(2)}
                  </b>
                </td>
                <td>
                  <b>
                    {makeTotal(data.customerUtilityBillPostSolar).toFixed(2)}
                  </b>
                </td>
              </tr>
            </tbody>
          </HTMLTable>
        </Card>
      </Styles.CardContainer>
      <IntakeForm />
    </div>
  );
};
