import { ILogin, IRegister, IUser } from "./Auth.interfaces";
import Cookies from "js-cookie";
import HTTP from "../../../app/axios.interceptor";
import { appHost } from "../../../app/root.vars";

const login = async (data: ILogin): Promise<IUser> => {
  return await HTTP.post("/auth/login", data);
};

const register = async (data: IRegister): Promise<IUser> => {
  return await HTTP.post("/auth/signup", data);
};

const logout = (): void => {
  Cookies.remove("user");
};

const getLocalRefreshToken = (): string => {
  const data = JSON.parse(Cookies.get("user") as string);
  return data?.security.refresh_token;
};

const getLocalAccessToken = (): { type: string; token: string } | null => {
  if (Cookies.get("user")) {
    const data = JSON.parse(Cookies.get("user") as string);
    return data?.security;
  } else {
    return null;
  }
};

const getLocalSession = () => {
  //return useAppSelector(userSession);
};

const setCookie = (data: any) => {
  Cookies.set("user", JSON.stringify(data));
};

const updateLocalAccessToken = (token: string): void => {
  let user = JSON.parse(Cookies.get("user") as string);
  user.security.token = token;
  Cookies.set("user", JSON.stringify(user), {
    expires: 7,
    secure: false,
    domain: appHost,
  });
};

export const authService = {
  login,
  register,
  logout,
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getLocalSession,
  setCookie,
};
