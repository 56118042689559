import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./Excel.initialState";

export const excelSlice = createSlice({
  name: "excel",
  initialState,
  reducers: {
    createExcelRequest(state, action) {
      state.isLoading = true;
    },
    createExcelSuccess(state) {
      state.isLoading = false;
    },
  },
});

export const { createExcelRequest, createExcelSuccess } = excelSlice.actions;

export default excelSlice.reducer;
