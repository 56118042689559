// @flow
import React, { useEffect } from "react";
import "../common/Auth.style.css";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { logoutRequest } from "../_services/Auth.slice";

type Props = {};
export const LogoutComponent = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logoutRequest("user"));
    navigate("/login");
  }, [navigate, dispatch]);

  return <div className={"login-component"}>Redirecting...</div>;
};
