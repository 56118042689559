import React from "react";
import { Button, Intent } from "@blueprintjs/core";
//import { IAuthState } from "../../_services/Auth.interfaces";
import { useAppSelector } from "../../../../app/hooks";
import { loggingIn } from "../../_services/Auth.slice";

const AuthSubmitComponent = (props: {
  text: string;
  icon: string;
  isValid: boolean;
}) => {
  const isLoggingIn = useAppSelector(loggingIn);
  return (
    <>
      <Button
        fill={true}
        type="submit"
        large={true}
        loading={isLoggingIn}
        disabled={isLoggingIn || !props.isValid}
        intent={Intent.PRIMARY}
        text={props.text}
      />
    </>
  );
};

export default AuthSubmitComponent;
