import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface AppInterface {
  theme: "light" | "dark";
  isMenuOpen: boolean;
  redirectToLogin: boolean;
  isIntuitAuthenticated: boolean;
  loading: { [index: number]: { name: string } };
}

const initialState: AppInterface = {
  theme: "light",
  isMenuOpen: false,
  isIntuitAuthenticated: false,
  loading: {},
  redirectToLogin: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    changeTheme: (state) => {
      state.theme = state.theme === "dark" ? "light" : "dark";
    },
    activateMenu: (state) => {
      state.isMenuOpen = state.isMenuOpen !== true;
    },
    addToLoading(state, action) {
      state.loading[action.payload.id] = { name: action.payload.name };
    },
    removeFromLoading(state, action) {
      delete state.loading[action.payload.id];
    },
    validateUUIDUrlAppRedirect(state, action) {
      if (!action.payload) {
        state.redirectToLogin = true;
      } else {
        state.redirectToLogin = false;
      }
    },
    authIntuit(state, action) {},
    isIntuitAuthRequest(state, action) {},
    setIntuitAuth(state, action) {
      state.isIntuitAuthenticated = action.payload;
    },
  },
});

export const isMenuOpen = (state: RootState) => state.app.isMenuOpen;
export const getTheme = (state: RootState) => state.app.theme;
export const loadingState = (state: RootState) => state.app.loading;
export const redirectLogin = (state: RootState) => state.app.redirectToLogin;
export const isIntuitAuthenticated = (state: RootState) =>
  state.app.isIntuitAuthenticated;

export const {
  changeTheme,
  activateMenu,
  addToLoading,
  removeFromLoading,
  validateUUIDUrlAppRedirect,
  setIntuitAuth,
  isIntuitAuthRequest,
} = appSlice.actions;
export default appSlice.reducer;
