import React from "react";
import { Styles } from "../../pages/home/Home.styled";
import { NavLink } from "react-router-dom";
import { Icon } from "@blueprintjs/core";

export const NetMeteringOverview = () => {
  return (
    <div className={"main"}>
      <Styles.WelcomeComponent>
        <Styles.WelcomeCard>
          <NavLink to={"/net-metering/solbid-tariffs"}>
            <div className="bp4-card bp4-interactive bp4-elevation-0 format-icon-card">
              <Icon icon={"helper-management"} size={50} />
              <h4 className="bp4-heading">Tariffs</h4>
            </div>
          </NavLink>
        </Styles.WelcomeCard>
        <Styles.WelcomeCard>
          <NavLink to={"/net-metering/monthly-outputs"}>
            <div className="bp4-card bp4-interactive bp4-elevation-0 format-icon-card">
              <Icon icon={"calendar"} size={50} />
              <h4 className="bp4-heading">Monthly Output</h4>
            </div>
          </NavLink>
        </Styles.WelcomeCard>
        <Styles.WelcomeCard>
          <NavLink to={"/net-metering/solar-programs"}>
            <div className="bp4-card bp4-interactive bp4-elevation-0 format-icon-card">
              <Icon icon={"flash"} size={50} />
              <h4 className="bp4-heading">Solar Programs</h4>
            </div>
          </NavLink>
        </Styles.WelcomeCard>
        <Styles.WelcomeCard>
          <NavLink to={"/net-metering/errors"}>
            <div className="bp4-card bp4-interactive bp4-elevation-0 format-icon-card">
              <Icon icon={"error"} size={50} />
              <h4 className="bp4-heading">Errors</h4>
            </div>
          </NavLink>
        </Styles.WelcomeCard>
        {/*<Styles.WelcomeCard>*/}
        {/*  <NavLink to={"/net-metering/excel"}>*/}
        {/*    <div className="bp4-card bp4-interactive bp4-elevation-0 format-icon-card">*/}
        {/*      <Icon icon={"calendar"} size={50} />*/}
        {/*      <h4 className="bp4-heading">Excel</h4>*/}
        {/*    </div>*/}
        {/*  </NavLink>*/}
        {/*</Styles.WelcomeCard>*/}
        <Styles.WelcomeCard>
          <NavLink to={"/net-metering/test-form"}>
            <div className="bp4-card bp4-interactive bp4-elevation-0 format-icon-card">
              <Icon icon={"application"} size={50} />
              <h4 className="bp4-heading">Test Form</h4>
            </div>
          </NavLink>
        </Styles.WelcomeCard>
      </Styles.WelcomeComponent>
    </div>
  );
};
