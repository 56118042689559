import { all, fork } from "redux-saga/effects";
import {
  watchAppInit,
  watchLogin,
  watchLogoutUser,
  watchRegister,
  watchUserSet,
} from "../pages/auth/_services/Auth.saga";
import {
  watchGetBuildingTypesSaga,
  watchGetEnergyIntensitySaga,
  watchGetMaxDemandSaga,
  watchGetMaxMonthlyUseSaga,
  watchGetOneUtilitySaga,
  watchGoogleStreetRequestSaga,
  watchRegenerateSolbidRates,
  watchSetBuildingSaga,
  watchTariffIdState,
  watchTariffRateSaga,
  watchUpdatePeakCategorySaga,
  watchUpdateRateCategorySaga,
  watchUpdateZipCode,
  watchUtilityRates,
  watchUtilityRatesOnline,
  watchUtilityRatesSecondTry,
} from "../net-metering/NetMetering.saga";
// import {
//   watchSubmitTheiaData,
//   watchTestTheia,
//   watchValidateAddress,
// } from "../theia/Theia.saga";
import { watchSocketInitSaga } from "../web-sockets/socket.saga";
import {
  calculateSolbidTariffsSaga,
  submitSolbidRatesSaga,
  toggleTariffActiveSaga,
  watchGetOnlineTariffsSaga,
  watchGetTariffsSaga,
  watchGetTariffsWithErrorsSaga,
  watchSolbidRatesRequest,
  watchSolbidRatesRequestRedirectSaga,
} from "../net-metering/solbid-tariffs/SolbidTariffs.saga";
import { watchGetStatesSaga } from "../net-metering/states/StatesDropdown.saga";
import { watchGetUtilityProvidersSaga } from "../net-metering/utility-providers/UtilityProviders.saga";
import { watchSubmitIntakeDebug } from "../net-metering/monthly-outputs/MonthlyOutputs.saga";
import {
  watchGetAllSolarProgramsByState,
  watchUpdateSolarProgram,
} from "../net-metering/solar-programs/SolarPrograms.saga";

import { watchExcel } from "../components/excel/excel.saga";

export default function* rootSaga() {
  yield all([
    fork(watchAppInit),
    fork(watchLogin),
    fork(watchRegister),
    fork(watchUserSet),
    fork(watchLogoutUser),
    fork(watchUpdateZipCode),
    fork(watchSocketInitSaga),
    fork(watchUtilityRates),
    fork(watchTariffRateSaga),
    fork(watchSetBuildingSaga),
    fork(watchGetEnergyIntensitySaga),
    fork(watchGetBuildingTypesSaga),
    fork(watchUpdateRateCategorySaga),
    fork(watchGetMaxMonthlyUseSaga),
    fork(watchGetMaxDemandSaga),
    fork(watchGetOneUtilitySaga),
    fork(watchGoogleStreetRequestSaga),
    fork(watchUtilityRatesOnline),
    fork(watchUtilityRatesSecondTry),
    fork(watchGetTariffsSaga),
    fork(watchGetStatesSaga),
    fork(watchGetUtilityProvidersSaga),
    fork(watchGetOnlineTariffsSaga),
    fork(watchSolbidRatesRequestRedirectSaga),
    fork(watchSolbidRatesRequest),
    fork(submitSolbidRatesSaga),
    fork(watchUpdatePeakCategorySaga),
    fork(toggleTariffActiveSaga),
    fork(calculateSolbidTariffsSaga),
    fork(watchSubmitIntakeDebug),
    fork(watchRegenerateSolbidRates),
    fork(watchGetTariffsWithErrorsSaga),
    fork(watchGetAllSolarProgramsByState),
    fork(watchUpdateSolarProgram),
    fork(watchExcel),
  ]);
}
