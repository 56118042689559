import React, { useEffect } from "react";
import {
  calculateSolbidRatesRequest,
  getIsModalOpen,
  getSelectedTariff,
  getSolbidRatesForm,
  isNetMeteringLoading,
  setTariffId,
  submitSolbidRatesRequest,
  toggleGenabilityModal,
  toggleTariffActiveRequest,
  toggleTariffModal,
} from "../NetMetering.slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  FormGroup,
  Intent,
  NumericInput,
} from "@blueprintjs/core";
import { Styles } from "../../styles/main.styled";
import { Controller, useForm } from "react-hook-form";
import { SolbidRates } from "../NetMetering.initialState";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { FormDivider } from "./FormDivider";

export const SolbidTariffForm = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(getIsModalOpen);
  const isLoading = useAppSelector(isNetMeteringLoading);
  const selectedTariff = useAppSelector(getSelectedTariff);
  const solbidRatesForm = useAppSelector(getSolbidRatesForm);

  const SolbidTariffSchema = Yup.object().shape({
    // zip: Yup.string().required("Zip Code is required"),
    distAllTime: Yup.string().required("Street is required"),
    // use_type: Yup.object()
    //   .shape({
    //     label: Yup.string().required("Required"),
    //     value: Yup.string().required("Required"),
    //   })
    //   .typeError("Building Use Type is required"),
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    //register,
    //watch,
    //formState,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm<SolbidRates>({
    resolver: yupResolver(SolbidTariffSchema),
    defaultValues: solbidRatesForm,
  });

  useEffect(() => {
    reset({ ...solbidRatesForm });
  }, [reset, solbidRatesForm]);

  const onSubmit = handleSubmit((data) => {
    if (!data.lseId) {
      data.lseId = selectedTariff.lseId;
    }
    if (!data.tariffId) {
      data.tariffId = selectedTariff.tariffId;
    }
    dispatch(submitSolbidRatesRequest(data));
    console.dir(data);
  });

  return (
    <div>
      <Dialog
        title={
          "[" + selectedTariff?.tariffCode + "] " + selectedTariff?.tariffName
        }
        icon="helper-management"
        style={{ width: "800px" }}
        onClose={() => dispatch(toggleTariffModal())}
        isOpen={isOpen}
      >
        <form onSubmit={onSubmit}>
          <DialogBody>
            <Styles.FormRow>
              <Controller
                control={control}
                name="custCharge"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="Customer Charge"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "160px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.custCharge && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
            <FormDivider text={"Distribution"} size={"big"} />
            <Styles.FormRow>
              <Controller
                control={control}
                name="distAllTime"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="All Time"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.distAllTime && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
            <FormDivider text={"Non-Summer"} size={"small"} />
            <Styles.FormRow>
              <Controller
                control={control}
                name="distOnPeak"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="On Peak"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.distOnPeak && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="distPartialPeak"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="Partial Peak"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.distPartialPeak && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="distOffPeak"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="Off Peak"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.distOffPeak && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
            <FormDivider text={"Summer"} size={"small"} />
            <Styles.FormRow>
              <Controller
                control={control}
                name="distOnPeakSummer"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="On Peak"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.distOnPeakSummer && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="distPartialPeakSummer"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="Partial Peak"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.distPartialPeakSummer && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="distOffPeakSummer"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="Off Peak"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.distOffPeakSummer && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
            <FormDivider text={"Supply"} size={"big"} />
            <Styles.FormRow>
              <Controller
                control={control}
                name="suppAllTime"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="All Time"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.suppAllTime && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
            <FormDivider text={"Non-Summer"} size={"small"} />
            <Styles.FormRow>
              <Controller
                control={control}
                name="suppOnPeak"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="On Peak"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.suppOnPeak && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="suppPartialPeak"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="Partial Peak"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.suppPartialPeak && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="suppOffPeak"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="Off Peak"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.suppOffPeak && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
            <FormDivider text={"Summer"} size={"small"} />
            <Styles.FormRow>
              <Controller
                control={control}
                name="suppOnPeakSummer"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="On Peak"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.suppOnPeakSummer && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="suppPartialPeakSummer"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="Partial Peak"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.suppPartialPeakSummer && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="suppOffPeakSummer"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="Off Peak"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.suppOffPeakSummer && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
            <FormDivider text={"Demand"} size={"big"} />
            <Styles.FormRow>
              <Controller
                control={control}
                name="demAllTime"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="All Time"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.demAllTime && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
            <FormDivider text={"Non-Summer"} size={"small"} />
            <Styles.FormRow>
              <Controller
                control={control}
                name="demOnPeak"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="On Peak"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.demOnPeak && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="demOffPeak"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="Off Peak"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.demOffPeak && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
            <FormDivider text={"Summer"} size={"small"} />
            <Styles.FormRow>
              <Controller
                control={control}
                name="demOnPeakSummer"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="On Peak"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.demOnPeakSummer && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="demOffPeakSummer"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup
                    label="Off Peak"
                    labelFor={name}
                    labelInfo={"*"}
                    style={{ marginRight: "16px" }}
                  >
                    <NumericInput
                      style={{ width: "120px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={"0.00"}
                      inputRef={ref}
                      intent={errors.demOffPeakSummer && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
          </DialogBody>
          <DialogFooter
            actions={
              <>
                <Button
                  intent={solbidRatesForm.id ? "primary" : "success"}
                  text={solbidRatesForm.id ? "Update" : "Create"}
                  loading={isLoading}
                  type={"submit"}
                />
                <Button
                  intent="none"
                  text="Close"
                  loading={isLoading}
                  onClick={() => dispatch(toggleTariffModal())}
                />
              </>
            }
          >
            <Button
              style={{
                marginRight: "10px",
              }}
              intent={selectedTariff.isActive ? "danger" : "success"}
              text={selectedTariff.isActive ? "Deactivate" : "Activate"}
              loading={isLoading}
              onClick={() =>
                dispatch(
                  toggleTariffActiveRequest({
                    tariffId: selectedTariff.tariffId,
                    isActive: !selectedTariff.isActive,
                  })
                )
              }
            />
            <Button
              style={{
                marginRight: "10px",
              }}
              intent={"primary"}
              text={"Genability"}
              loading={isLoading}
              onClick={() => {
                dispatch(setTariffId(selectedTariff.tariffId));
                dispatch(toggleGenabilityModal(true));
              }}
            />
            <Button
              intent={"success"}
              text={"Calculate Rates"}
              loading={isLoading}
              onClick={() => {
                dispatch(calculateSolbidRatesRequest(selectedTariff.tariffId));
              }}
            />
          </DialogFooter>
        </form>
      </Dialog>
    </div>
  );
};
