// @flow
import React from "react";
import { Outlet } from "react-router-dom";
import AuthNavbar from "../../pages/auth/common/AuthNavbar";

type Props = {};
export const AuthLayout = (props: Props) => {
  return (
    <>
      <AuthNavbar />
      {/* An <Outlet> renders whatever child route is currently active,
            so you can think about this <Outlet> as a placeholder for
            the child routes we defined above. */}
      <Outlet />
    </>
  );
};
