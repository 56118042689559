import React, { useEffect } from "react";
import "../common/Auth.style.css";
import { Card, Elevation, FormGroup, InputGroup } from "@blueprintjs/core";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useForm, Controller } from "react-hook-form";
import { loggedIn, loginRequest } from "../_services/Auth.slice";
import { ILogin } from "../_services/Auth.interfaces";
import AuthSubmitComponent from "../common/AuthSubmit/AuthSubmit.component";
import { Styles } from "../../../styles/main.styled";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .required("Password is required"),
});

const LoginFormComponent = () => {
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector(loggedIn);
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/home");
    }
  }, [navigate, isLoggedIn]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILogin>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(LoginSchema),
  });
  const dispatch = useAppDispatch();
  const onSubmit = handleSubmit((data) => dispatch(loginRequest(data)));

  return (
    <>
      <Styles.CardContainer style={{ alignItems: 'center'}}>
        <div className={"login_div"}>
          <h1>Login</h1>
          <Card elevation={Elevation.TWO}>
            <form onSubmit={onSubmit}>
              <FormGroup
                helperText={errors.email?.message}
                label="E-Mail"
                labelFor="email"
                labelInfo=""
              >
                <Controller
                  control={control}
                  name="email"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <InputGroup
                      id="email"
                      placeholder="Email or username"
                      type={"text"}
                      name={name}
                      leftIcon={"envelope"}
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup
                helperText={errors.password?.message}
                label="Password"
                labelFor="text-input"
                labelInfo=""
              >
                <Controller
                  control={control}
                  name="password"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <InputGroup
                      id="password"
                      placeholder="Your password..."
                      type={"password"}
                      name={name}
                      leftIcon={"lock"}
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
              </FormGroup>

              <div className={"auth-buttons"}>
                <AuthSubmitComponent
                  text={"Login"}
                  icon={"login"}
                  isValid={true}
                />
              </div>
              <br />
              <div style={{ textAlign: "center" }}>
                By Logging in, you agree to our Terms and Conditions and Privacy
                Statement.
              </div>
            </form>
          </Card>
        </div>
      </Styles.CardContainer>
    </>
  );
};

export default LoginFormComponent;
