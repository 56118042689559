import Cookies from "js-cookie";
import { IAuthState, IUser } from "./Auth.interfaces";

let data: IAuthState | any = Cookies.get("user")
  ? JSON.parse(Cookies.get("user") as string)
  : null;

export const userInitialState: IUser = {
  id: 0,
  name: "",
  email: "",
};

export const initialState: IAuthState | any = data
  ? {
      appInit: false,
      loggedIn: true,
      loggingIn: false,
      isSubmitting: false,
      user: data.user,
      session: data.security,
    }
  : {
      appInit: false,
      loggedIn: false,
      loggingIn: false,
      isSubmitting: false,
      user: {
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
      },
      session: {
        token: "",
        refresh_token: "",
        type: "",
      },
      data: {},
    };
