import React from "react";
import { Outlet } from "react-router-dom";
import TopNavbar from "../../pages/auth/common/HomeNavbar";

type Props = {};
export const NetMeteringLayout = (props: Props) => {
  return (
    <>
      <TopNavbar />
      {/* An <Outlet> renders whatever child route is currently active,
            so you can think about this <Outlet> as a placeholder for
            the child routes we defined above. */}
      <Outlet />
    </>
  );
};
