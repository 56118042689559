import {
  call,
  CallEffect,
  put,
  PutEffect,
  SelectEffect,
  takeLatest,
} from "@redux-saga/core/effects";
import {
  submitIntakeRequest,
  submitIntakeSuccess,
  toggleIntakeForm,
} from "../NetMetering.slice";
import { monthlyOutputServices } from "./MonthlyOutputs.services";

// Workers

function* fetchIntakeDebug({
  payload,
}: any): Generator<PutEffect | SelectEffect | CallEffect> {
  try {
    const response = yield call(monthlyOutputServices.getDebugData, payload);
    console.dir(response);
    yield put(submitIntakeSuccess(response));
    yield put(toggleIntakeForm());
  } catch (e) {}
}

// Watchers

export function* watchSubmitIntakeDebug() {
  yield takeLatest(submitIntakeRequest, fetchIntakeDebug);
}
