import * as React from "react";
import { HTMLSelect } from "@blueprintjs/core";
import {
  getStatesDropdown,
  getStatesDropdownRequest,
} from "../NetMetering.slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect } from "react";
import { getAllByStateRequest, getIsLoading } from "./SolarPrograms.slice";

export const SolarProgramsStatesDropdown = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getIsLoading);
  const states = useAppSelector(getStatesDropdown);

  useEffect(() => {
    dispatch(getStatesDropdownRequest());
  }, [dispatch]);

  return (
    <div>
      <HTMLSelect
        name={"state"}
        onChange={(event) => {
          dispatch(getAllByStateRequest(event.currentTarget.value));
        }}
        //placeholder={"Select a state..."}
        defaultValue={0}
        disabled={isLoading}
        style={{ width: "170px" }}
        options={[{ value: 0, label: "Select a state...", disabled: true }, ...states]}
      />
    </div>
  );
};
