import React from "react";
import "../common/Auth.style.css";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Card, Elevation, FormGroup, InputGroup } from "@blueprintjs/core";
import { NavLink } from "react-router-dom";
import { IRegister } from "../_services/Auth.interfaces";
import { useDispatch } from "react-redux";
import { registerRequest } from "../_services/Auth.slice";

import { Controller, useForm } from "react-hook-form";
import AuthSubmitComponent from "../common/AuthSubmit/AuthSubmit.component";

const RegisterSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First Name Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last Name Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .required("Password is required"),
  repeatPassword: Yup.string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password is required"),
});

const RegisterFormComponent = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IRegister>({
    resolver: yupResolver(RegisterSchema),
  });
  const dispatch = useDispatch();
  const onSubmit = handleSubmit((data) => dispatch(registerRequest(data)));
  return (
    <>
      <div className="login_div">
        <Card elevation={Elevation.ONE}>
          <form onSubmit={onSubmit}>
            <FormGroup
              helperText={errors.firstName?.message}
              label="First Name"
              labelFor="text-input"
              labelInfo=""
            >
              <Controller
                control={control}
                name="firstName"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <InputGroup
                    id="firstName"
                    placeholder="Your First Name"
                    type={"text"}
                    name={name}
                    leftIcon={"person"}
                    onBlur={onBlur} // notify when input is touched
                    onChange={onChange} // send value to hook form
                    inputRef={ref}
                  />
                )}
              />
            </FormGroup>
            <FormGroup
              helperText={errors.lastName?.message}
              label="Last Name"
              labelFor="text-input"
              labelInfo=""
            >
              <Controller
                control={control}
                name="lastName"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <InputGroup
                    id="lastName"
                    placeholder="Your Last Name"
                    type={"text"}
                    name={name}
                    leftIcon={"person"}
                    onBlur={onBlur} // notify when input is touched
                    onChange={onChange} // send value to hook form
                    inputRef={ref}
                  />
                )}
              />
            </FormGroup>

            <FormGroup
              helperText={errors.email?.message}
              label="Email"
              labelFor="text-input"
              labelInfo=""
            >
              <Controller
                control={control}
                name="email"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <InputGroup
                    id="email"
                    placeholder="Your email"
                    type={"text"}
                    name={name}
                    leftIcon={"envelope"}
                    onBlur={onBlur} // notify when input is touched
                    onChange={onChange} // send value to hook form
                    inputRef={ref}
                  />
                )}
              />
            </FormGroup>
            <FormGroup
              helperText={errors.password?.message}
              label="Password"
              labelFor="text-input"
              labelInfo=""
            >
              <Controller
                control={control}
                name="password"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <InputGroup
                    id="password"
                    placeholder="Your password"
                    type={"password"}
                    name={name}
                    leftIcon={"lock"}
                    onBlur={onBlur} // notify when input is touched
                    onChange={onChange} // send value to hook form
                    inputRef={ref}
                  />
                )}
              />
            </FormGroup>
            <FormGroup
              helperText={errors.repeatPassword?.message}
              label="Repeat Password"
              labelFor="text-input"
              labelInfo=""
            >
              <Controller
                control={control}
                name="repeatPassword"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <InputGroup
                    id="repeatPassword"
                    placeholder="Repeat password"
                    type={"password"}
                    name={name}
                    leftIcon={"lock"}
                    onBlur={onBlur} // notify when input is touched
                    onChange={onChange} // send value to hook form
                    inputRef={ref}
                  />
                )}
              />
            </FormGroup>
            <div className={"auth-buttons"}>
              <div>
                <NavLink
                  to="/login"
                  className="bp4-button bp4-icon-log-in"
                  style={{ marginRight: "5px" }}
                />
              </div>
              <AuthSubmitComponent
                text={"Register"}
                icon={"reg"}
                isValid={true}
              />
            </div>
          </form>
        </Card>
      </div>
    </>
  );
};

export default RegisterFormComponent;
