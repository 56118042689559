// @flow
import React from "react";

type Props = {
  title: string;
  leftIcon?: string;
  content?: React.ReactNode;
};

export const CardHeader = (props: Props) => {
  return (
    <div className={"bp4-dialog-header"}>
      {props.leftIcon ? (
        <span className={"bp4-icon-large bp4-icon-" + props.leftIcon}></span>
      ) : null}
      <h5 className={"bp4-heading"}>{props.title}</h5>
      <div>{props.content}</div>
    </div>
  );
};
