import axios from "axios";
import rootVars from "../../app/root.vars";
import { axiosNemConfig } from "../../app/axios.interceptor";

const getTariffs = async (payload: any) => {
  let ret = null;
  try {
    if (payload.lseId === "0") {
      ret = [];
    } else {
      await axios
        .get(
          `${rootVars.nemBaseURL}/tariffs/get-all-by-lse-id/${payload.lseId}/${payload.stateId}`,
          axiosNemConfig
        )
        .then((response) => {
          ret = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return ret;
  } catch (e) {
    console.log(e);
  }
};

const getOnlineTariffs = async (payload: number) => {
  let ret = null;
  try {
    await axios
      .post(
        `${rootVars.nemBaseURL}/tariffs/get-all-online-by-lse-id`,
        {
          lseId: payload,
        },
        axiosNemConfig
      )
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

const getSolbidRates = async (payload: number) => {
  let ret = null;
  try {
    await axios
      .get(
        `${rootVars.nemBaseURL}/solbid-rates/find-one-by-tariff/${payload}`,
        axiosNemConfig
      )
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

const getTariffsWithErrors = async () => {
  let ret = null;
  try {
    await axios
      .get(
        `${rootVars.nemBaseURL}/requests/get-all-tariffs-with-errors`,
        axiosNemConfig
      )
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

const submitSolbidRates = async (payload: number) => {
  let ret = null;
  try {
    await axios
      .post(`${rootVars.nemBaseURL}/solbid-rates`, payload, axiosNemConfig)
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

const submitToggleTariff = async (payload: {
  isActive: boolean;
  tariffId: number;
}) => {
  let ret = null;
  try {
    await axios
      .post(
        `${rootVars.nemBaseURL}/tariffs/deactivate-tariff`,
        payload,
        axiosNemConfig
      )
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

const calculateSolbidTariffs = async (tariffId: number) => {
  let ret = null;
  try {
    await axios
      .get(
        `${rootVars.nemBaseURL}/tariffs/calculate-rates/${tariffId}`,
        axiosNemConfig
      )
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

export const solbidTariffsServices = {
  getTariffs,
  getOnlineTariffs,
  getSolbidRates,
  submitSolbidRates,
  submitToggleTariff,
  calculateSolbidTariffs,
  getTariffsWithErrors,
};
