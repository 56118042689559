// @flow
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { appInit, loggedIn } from "../../pages/auth/_services/Auth.slice";

type Props = {};
export const InitComponent = (props: Props) => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(loggedIn);
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(appInit());
    }
  }, [dispatch, isLoggedIn]);

  return <div></div>;
};
