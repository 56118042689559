import { call, put, takeLatest } from "@redux-saga/core/effects";
import { createExcelRequest, createExcelSuccess } from "./excel.slice";
import { excelServices } from "./excel.services";

// Saga Workers

export function* fetchExcel(): any {
  try {
    const itemsForExcel = yield call(excelServices.getAllForExcel);
    yield call(excelServices.generateExcel, itemsForExcel);

    yield put(createExcelSuccess());
  } catch (error: any) {}
}

// Saga Watchers
export function* watchExcel() {
  yield takeLatest(createExcelRequest, fetchExcel);
}
