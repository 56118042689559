import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getAllBuildingsDropdown,
  getBuildingTypesRequest,
  getSolbidRatesRequest,
  getTariffsDropdown,
  getTariffsRequest,
  getUtilitiesDropdown,
  isNetMeteringLoading,
  selectIsIntakeModalOpen,
  submitIntakeRequest,
  toggleIntakeForm,
} from "../NetMetering.slice";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  FormGroup,
  InputGroup,
  Intent,
  NumericInput,
  HTMLSelect,
} from "@blueprintjs/core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { initialState, IntakeData } from "../NetMetering.initialState";
import { Styles } from "../../styles/main.styled";
import { StatesDropdownComponent } from "../states/StatesDropdown.component";

export const IntakeForm = () => {
  const isOpen = useAppSelector(selectIsIntakeModalOpen);
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isNetMeteringLoading);
  const utilities = useAppSelector(getUtilitiesDropdown);
  const tariffsDropdown = useAppSelector(getTariffsDropdown);
  const useTypeOptions = useAppSelector(getAllBuildingsDropdown);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IntakeData>({
    defaultValues: initialState.intake,
  });

  useEffect(() => {
    dispatch(getBuildingTypesRequest());
  }, [dispatch]);

  const onSubmit: SubmitHandler<IntakeData> = (data) =>
    dispatch(submitIntakeRequest(data));

  return (
    <div>
      <Dialog
        isOpen={isOpen}
        title={"Intake Form"}
        onClose={() => dispatch(toggleIntakeForm())}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogBody>
            <Styles.FormRow>
              <Controller
                control={control}
                name="lat"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    style={{ width: "200px", marginRight: "10px" }}
                    helperText={
                      errors.lat && <span>This field is required</span>
                    }
                    label="Latitute"
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.lat && Intent.DANGER}
                  >
                    <InputGroup
                      id={name}
                      name={name}
                      type={"text"}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      intent={errors.lat && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="lon"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    style={{ width: "200px" }}
                    helperText={
                      errors.lon && <span>This field is required</span>
                    }
                    label="Longitude"
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.lon && Intent.DANGER}
                  >
                    <InputGroup
                      id={name}
                      name={name}
                      type={"text"}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      intent={errors.lon && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
            <Styles.FormRow>
              <Controller
                control={control}
                name="solarSysSize"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    style={{ width: "211px" }}
                    helperText={
                      errors.solarSysSize && <span>This field is required</span>
                    }
                    label="Solar Sys Size"
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.solarSysSize && Intent.DANGER}
                  >
                    <NumericInput
                      style={{ width: "100px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={1}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={name}
                      inputRef={ref}
                      intent={errors.solarSysSize && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="azimuth"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    style={{ width: "211px" }}
                    helperText={
                      errors.azimuth && <span>This field is required</span>
                    }
                    label="Azimuth"
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.azimuth && Intent.DANGER}
                  >
                    <NumericInput
                      style={{ width: "100px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={1}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={name}
                      inputRef={ref}
                      intent={errors.azimuth && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="arrayTilt"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    style={{ width: "211px" }}
                    helperText={
                      errors.arrayTilt && <span>This field is required</span>
                    }
                    label="Array Tilt"
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.arrayTilt && Intent.DANGER}
                  >
                    <NumericInput
                      style={{ width: "100px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={1}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={name}
                      inputRef={ref}
                      intent={errors.arrayTilt && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
            <Styles.FormRow>
              <Controller
                control={control}
                name="arrayType"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    style={{ width: "211px" }}
                    helperText={
                      errors.arrayType && <span>This field is required</span>
                    }
                    label="Array Type"
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.arrayType && Intent.DANGER}
                  >
                    <NumericInput
                      style={{ width: "100px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={1}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={name}
                      inputRef={ref}
                      intent={errors.arrayType && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="inverterEfficiency"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    style={{ width: "211px" }}
                    helperText={
                      errors.inverterEfficiency && (
                        <span>This field is required</span>
                      )
                    }
                    label="Inverter Efficiency"
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.inverterEfficiency && Intent.DANGER}
                  >
                    <NumericInput
                      style={{ width: "100px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={1}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={name}
                      inputRef={ref}
                      intent={errors.inverterEfficiency && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="moduleType"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    style={{ width: "211px" }}
                    helperText={
                      errors.moduleType && <span>This field is required</span>
                    }
                    label="Module Type"
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.moduleType && Intent.DANGER}
                  >
                    <NumericInput
                      style={{ width: "100px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={1}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={name}
                      inputRef={ref}
                      intent={errors.moduleType && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
            <Styles.FormRow>
              <Controller
                control={control}
                name="sysLoss"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    style={{ width: "211px" }}
                    helperText={
                      errors.sysLoss && <span>This field is required</span>
                    }
                    label="Sys Loss Type"
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.sysLoss && Intent.DANGER}
                  >
                    <NumericInput
                      style={{ width: "100px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.01}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={name}
                      inputRef={ref}
                      intent={errors.sysLoss && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="radius"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    style={{ width: "211px" }}
                    helperText={
                      errors.radius && <span>This field is required</span>
                    }
                    label="Radius"
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.radius && Intent.DANGER}
                  >
                    <NumericInput
                      style={{ width: "100px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={1}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={name}
                      inputRef={ref}
                      intent={errors.radius && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="dcAcRatio"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    style={{ width: "211px" }}
                    helperText={
                      errors.dcAcRatio && <span>This field is required</span>
                    }
                    label="DC/AC Ratio"
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.dcAcRatio && Intent.DANGER}
                  >
                    <NumericInput
                      style={{ width: "100px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={0.1}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={name}
                      inputRef={ref}
                      intent={errors.dcAcRatio && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
            <Styles.FormRow>
              <Controller
                control={control}
                name="timeFrame"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    style={{ width: "178px", marginRight: "26px" }}
                    helperText={
                      errors.timeFrame && <span>This field is required</span>
                    }
                    label="Time Frame"
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.timeFrame && Intent.DANGER}
                  >
                    <InputGroup
                      id={name}
                      name={name}
                      type={"text"}
                      onBlur={onBlur}
                      disabled={true}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      intent={errors.timeFrame && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="annualKwh"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    style={{ width: "211px" }}
                    helperText={
                      errors.annualKwh && <span>This field is required</span>
                    }
                    label="Annual KWh"
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.annualKwh && Intent.DANGER}
                  >
                    <NumericInput
                      style={{ width: "100px" }}
                      id={name}
                      name={name}
                      onBlur={onBlur}
                      onValueChange={onChange}
                      value={value}
                      minorStepSize={1}
                      stepSize={1}
                      asyncControl={true}
                      min={0.0}
                      placeholder={name}
                      inputRef={ref}
                      intent={errors.annualKwh && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
            <Styles.FormRow>
              <Controller
                control={control}
                name="zip"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    style={{ width: "130px" }}
                    helperText={
                      errors.zip && <span>This field is required</span>
                    }
                    label="Zip Code"
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.zip && Intent.DANGER}
                  >
                    <InputGroup
                      id={name}
                      name={name}
                      type={"text"}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      intent={errors.zip && Intent.DANGER}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="buildingTypeId"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    helperText={
                      errors.buildingTypeId && (
                        <span>This field is required</span>
                      )
                    }
                    label="Building Use Type"
                    labelFor={name}
                    labelInfo="*"
                    style={{ marginLeft: "16px" }}
                    intent={errors.buildingTypeId && Intent.DANGER}
                  >
                    <HTMLSelect
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      //placeholder={name}
                      defaultValue={0}
                      style={{ width: "200px" }}
                      options={[
                        { value: 0, label: "Select...", disabled: true },
                        ...useTypeOptions,
                      ]}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
            <Styles.FormRow>
              <FormGroup style={{ width: "211px" }} label="State" labelInfo="*">
                <StatesDropdownComponent />
              </FormGroup>
              <Controller
                control={control}
                name="lseId"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    style={{ width: "310px" }}
                    helperText={
                      errors.lseId && <span>This field is required</span>
                    }
                    label="Provider"
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.lseId && Intent.DANGER}
                  >
                    <HTMLSelect
                      name={name}
                      onBlur={onBlur}
                      onChange={(event) => {
                        onChange(event);
                        dispatch(getTariffsRequest(event.currentTarget.value));
                      }}
                      defaultValue={"0"}
                      disabled={isLoading}
                      style={{ width: "100%" }}
                      options={[
                        { value: "0", label: "Select...", disabled: false },
                        ...utilities,
                      ]}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
            <Styles.FormRow>
              <Controller
                control={control}
                name="tariffId"
                rules={{ required: true }}
                render={({ field: { onChange, name, onBlur, value, ref } }) => (
                  <FormGroup
                    style={{ width: "100%" }}
                    helperText={
                      errors.tariffId && <span>This field is required</span>
                    }
                    label="Tariff"
                    labelFor={name}
                    labelInfo="*"
                    intent={errors.tariffId && Intent.DANGER}
                  >
                    <HTMLSelect
                      name={name}
                      onBlur={onBlur}
                      onChange={(event) => {
                        onChange(event);
                        dispatch(
                          getSolbidRatesRequest({
                            tariffId: event.currentTarget.value,
                          })
                        );
                      }}
                      defaultValue={"0"}
                      disabled={isLoading}
                      style={{ width: "100%" }}
                      options={[
                        { value: "0", label: "Select...", disabled: false },
                        ...tariffsDropdown,
                      ]}
                    />
                  </FormGroup>
                )}
              />
            </Styles.FormRow>
          </DialogBody>
          <DialogFooter
            actions={
              <>
                <Button
                  loading={isLoading}
                  type={"submit"}
                  text={"Submit"}
                  small={true}
                  intent={"primary"}
                />
              </>
            }
          />
        </form>
      </Dialog>
    </div>
  );
};
