import { createSlice } from "@reduxjs/toolkit";
import { SolarProgramsInitialState } from "./SolarPrograms.initialState";
import { RootState } from "../../app/store";

// Define initial state
const initialState: SolarProgramsInitialState = {
  data: [],
  isLoading: false,
  isOpen: false,
  error: null,
  activeSolarProgram: {
    id: 0,
    lseId: 0,
    solarRateType: 0,
    solarProgramType: 0,
    solarRate: 0,
  },
};

// Create the slice
const solarProgramsSlice = createSlice({
  name: "solarPrograms",
  initialState,
  reducers: {
    // Action for reading entities
    getAllByStateRequest(state, action) {
      state.isLoading = true;
      state.error = null;
    },
    getAllByStateSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    getAllByStateError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Action for setting the record for update form
    setSelected(state, action) {
      state.error = null;
      state.activeSolarProgram = action.payload;
      state.isOpen = true;
    },
    unsetSelected(state) {
      state.isOpen = false;
      state.activeSolarProgram = undefined;
    },
    // Action for updating an entity
    updateSolarProgramRequest(state, action) {
      state.isLoading = true;
      state.error = null;
    },
    updateSolarProgramSuccess(state, action) {
      const index = state.data.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        state.data[index] = action.payload;
      }
      state.isLoading = false;
      state.isOpen = false;
    },
    updateSolarProgramError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

// Extract the action creators
export const {
  getAllByStateRequest,
  getAllByStateSuccess,
  getAllByStateError,
  setSelected,
  unsetSelected,
  updateSolarProgramRequest,
  updateSolarProgramSuccess,
  updateSolarProgramError,
} = solarProgramsSlice.actions;

export const getSolarPrograms = (state: RootState) => state.solarPrograms.data;
export const getIsLoading = (state: RootState) => state.solarPrograms.isLoading;
export const getIsOpen = (state: RootState) => state.solarPrograms.isOpen;
export const getActiveSolarProgram = (state: RootState) =>
  state.solarPrograms.activeSolarProgram;

// Export the reducer
export default solarProgramsSlice.reducer;
