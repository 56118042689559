import axios from "axios";
import rootVars from "../../app/root.vars";
import { axiosNemConfig } from "../../app/axios.interceptor";

const getAllSolarRates = async () => {
  let ret = null;
  try {
    await axios
      .get(`${rootVars.nemBaseURL}/solar-rates`, axiosNemConfig)
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

const getAllSolarRatesByState = async (stateId: string) => {
  let ret = null;
  try {
    await axios
      .get(
        `${rootVars.nemBaseURL}/solar-rates/${stateId}/by-state-id`,
        axiosNemConfig
      )
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

const updateSolarRate = async (
  id: number,
  payload: {
    solarRateType: string;
    solarProgramType: string;
    solarRate: string;
  }
) => {
  let ret = null;
  try {
    await axios
      .patch(
        `${rootVars.nemBaseURL}/solar-rates/${id}`,
        payload,
        axiosNemConfig
      )
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

export const solarProgramsServices = {
  getAllSolarRates,
  getAllSolarRatesByState,
  updateSolarRate,
};
