import axios from "axios";
import rootVars from "../../app/root.vars";
import { axiosNemConfig } from "../../app/axios.interceptor";
import { IntakeData } from "../NetMetering.initialState";
import { TariffRate } from "../NetMetering.slice";

const getDebugData = async (payload: IntakeData) => {
  let ret = null;
  try {
    await axios
      .post(
        `${rootVars.nemBaseURL}/requests/pv-solar-generation`,
        payload,
        axiosNemConfig
      )
      .then((response) => {
        ret = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return ret;
  } catch (e) {
    console.log(e);
  }
};

const formatRatesForDropdown = (tariffs: TariffRate[]) => {
  let dropdown: { value: number; label: string }[] = [];
  tariffs.forEach((tariff) => {
    dropdown.push({
      value: tariff.tariffRateId,
      label: tariff.tariffCode + ": " + tariff.tariffName,
    });
  });

  return dropdown;
};

export const monthlyOutputServices = {
  getDebugData,
  formatRatesForDropdown,
};
