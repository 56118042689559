import React from "react";
import { Card, Elevation, HTMLTable } from "@blueprintjs/core";
import { getSolbidRatesForm } from "../NetMetering.slice";
import { useAppSelector } from "../../app/hooks";

export const RatesComponent = () => {
  const rates = useAppSelector(getSolbidRatesForm);
  const topBorder = {
    boxShadow: "inset 1px 1px 0 0 rgba(17, 20, 24, 0.15)",
  };

  return (
    <Card
      elevation={Elevation.ONE}
      style={{ padding: "0", overflowX: "auto", maxWidth: "100%" }}
    >
      <HTMLTable
        style={{ width: "1400px" }}
        interactive={true}
        bordered={true}
        striped={true}
        compact={true}
      >
        <thead>
          <tr>
            <th></th>
            <th>Distribution</th>
            <th colSpan={3}>Non-Summer</th>
            <th colSpan={3}>Summer</th>
            <th>Supply</th>
            <th colSpan={3}>Non-Summer</th>
            <th colSpan={3}>Summer</th>
            <th>Demand</th>
            <th colSpan={2}>Non-Summer</th>
            <th colSpan={2}>Summer</th>
          </tr>
          <tr>
            <th>Customer Charge</th>
            <th style={topBorder}>All Time</th>
            <th style={topBorder}>On</th>
            <th style={topBorder}>Partial</th>
            <th style={topBorder}>Off</th>
            <th style={topBorder}>On</th>
            <th style={topBorder}>Partial</th>
            <th style={topBorder}>Off</th>
            <th style={topBorder}>All Time</th>
            <th style={topBorder}>On</th>
            <th style={topBorder}>Partial</th>
            <th style={topBorder}>Off</th>
            <th style={topBorder}>On</th>
            <th style={topBorder}>Partial</th>
            <th style={topBorder}>Off</th>
            <th style={topBorder}>All Time</th>
            <th style={topBorder}>On</th>
            <th style={topBorder}>Off</th>
            <th style={topBorder}>On</th>
            <th style={topBorder}>Off</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{rates.custCharge}</td>
            <td>{rates.distAllTime}</td>
            <td>{rates.distOnPeak}</td>
            <td>{rates.distPartialPeak}</td>
            <td>{rates.distOffPeak}</td>
            <td>{rates.distOnPeakSummer}</td>
            <td>{rates.distPartialPeakSummer}</td>
            <td>{rates.distOffPeakSummer}</td>
            <td>{rates.suppAllTime}</td>
            <td>{rates.suppOnPeak}</td>
            <td>{rates.suppPartialPeak}</td>
            <td>{rates.suppOffPeak}</td>
            <td>{rates.suppOnPeakSummer}</td>
            <td>{rates.suppPartialPeakSummer}</td>
            <td>{rates.suppOffPeakSummer}</td>
            <td>{rates.demAllTime}</td>
            <td>{rates.demOnPeak}</td>
            <td>{rates.demOffPeak}</td>
            <td>{rates.demOnPeakSummer}</td>
            <td>{rates.demOffPeakSummer}</td>
          </tr>
        </tbody>
      </HTMLTable>
    </Card>
  );
};
