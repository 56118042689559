import {
  call,
  CallEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeLatest,
} from "@redux-saga/core/effects";
import {
  calculateSolbidRatesRequest,
  calculateSolbidRatesSuccess,
  getOnlineTariffsRequest,
  getSolbidRatesRequest,
  getSolbidRatesSuccess,
  getSolbidTariffRequest,
  getTariffsDropdownSuccess,
  getTariffsRequest,
  getTariffsSuccess,
  getTariffsWithErrorsRequest,
  getTariffsWithErrorsSuccess,
  selectedState,
  submitSolbidRatesRequest,
  submitSolbidRatesSuccess,
  toggleTariffActiveRequest,
  toggleTariffActiveSuccess,
} from "../NetMetering.slice";
import { solbidTariffsServices } from "./SolbidTariffs.services";

// Workers

function* fetchGetTariffRates({
  payload,
}: any): Generator<PutEffect | SelectEffect | CallEffect> {
  const selectedStateId = yield select(selectedState);
  try {
    const response = yield call(solbidTariffsServices.getTariffs, {
      lseId: payload,
      stateId: selectedStateId,
    });
    yield put(getTariffsSuccess(response));
    yield put(getTariffsDropdownSuccess(response));
  } catch (e) {}
}

function* fetchGetTariffWithErrors(): Generator<
  PutEffect | SelectEffect | CallEffect
> {
  try {
    const response = yield call(solbidTariffsServices.getTariffsWithErrors);
    yield put(getTariffsWithErrorsSuccess(response));
  } catch (e) {}
}

function* fetchGetOnlineTariffRates({
  payload,
}: any): Generator<PutEffect | SelectEffect | CallEffect> {
  try {
    yield call(solbidTariffsServices.getOnlineTariffs, payload);
    yield put(getTariffsRequest(payload));
  } catch (e) {}
}

function* redirectToSolbidRatesRequest({
  payload,
}: any): Generator<PutEffect | SelectEffect | CallEffect> {
  try {
    yield put(getSolbidRatesRequest(payload));
  } catch (e) {}
}

function* fetchGetSolbidRates({
  payload,
}: any): Generator<PutEffect | SelectEffect | CallEffect> {
  try {
    const response = yield call(
      solbidTariffsServices.getSolbidRates,
      payload.tariffId
    );
    yield put(getSolbidRatesSuccess(response));
  } catch (e) {}
}

function* fetchToggleActiveTariff({
  payload,
}: any): Generator<PutEffect | SelectEffect | CallEffect> {
  try {
    yield call(solbidTariffsServices.submitToggleTariff, payload);
    yield put(toggleTariffActiveSuccess());
  } catch (e) {}
}

function* fetchCalculateSolbidTariffs({
  payload,
}: any): Generator<PutEffect | SelectEffect | CallEffect> {
  try {
    const response = yield call(
      solbidTariffsServices.calculateSolbidTariffs,
      payload
    );
    yield put(calculateSolbidRatesSuccess(response));
  } catch (e) {}
}

function* fetchSubmitSolbidRates({
  payload,
}: any): Generator<PutEffect | SelectEffect | CallEffect> {
  try {
    yield call(solbidTariffsServices.submitSolbidRates, payload);
    yield put(submitSolbidRatesSuccess());
  } catch (e) {}
}

// Watchers

export function* watchGetTariffsSaga() {
  yield takeLatest(getTariffsRequest, fetchGetTariffRates);
}

export function* watchGetTariffsWithErrorsSaga() {
  yield takeLatest(getTariffsWithErrorsRequest, fetchGetTariffWithErrors);
}

export function* watchGetOnlineTariffsSaga() {
  yield takeLatest(getOnlineTariffsRequest, fetchGetOnlineTariffRates);
}

export function* watchSolbidRatesRequestRedirectSaga() {
  yield takeLatest(getSolbidTariffRequest, redirectToSolbidRatesRequest);
}

export function* watchSolbidRatesRequest() {
  yield takeLatest(getSolbidRatesRequest, fetchGetSolbidRates);
}

export function* submitSolbidRatesSaga() {
  yield takeLatest(submitSolbidRatesRequest, fetchSubmitSolbidRates);
}

export function* toggleTariffActiveSaga() {
  yield takeLatest(toggleTariffActiveRequest, fetchToggleActiveTariff);
}

export function* calculateSolbidTariffsSaga() {
  yield takeLatest(calculateSolbidRatesRequest, fetchCalculateSolbidTariffs);
}
