import React from "react";
import { HTMLSelect } from "@blueprintjs/core";
import {
  getTariffsRequest,
  getUtilitiesDropdown,
  isNetMeteringLoading,
} from "../NetMetering.slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

export const UtilitiesDropdownComponent = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isNetMeteringLoading);
  const utilities = useAppSelector(getUtilitiesDropdown);

  return (
    <div style={{ marginRight: "20px" }}>
      <HTMLSelect
        name={"lseId"}
        onChange={(event) => {
          dispatch(getTariffsRequest(event.currentTarget.value));
        }}
        defaultValue={"0"}
        disabled={isLoading}
        style={{ width: "100%" }}
        options={[
          { value: "0", label: "Select a utility provider...", disabled: false },
          ...utilities,
        ]}
      />
    </div>
  );
};
