import { ILogin, IRegister, IUser } from "./Auth.interfaces";

export enum authConstants {
  USER_LOGIN_REQUEST = "auth/loginRequest",
  USER_LOGIN_SUCCESS = "auth/loginSuccess",
  USER_LOGIN_FAILURE = "auth/loginError",

  USER_REGISTER_REQUEST = "USER/REGISTER_REQUEST",
  USER_REGISTER_SUCCESS = "USER/REGISTER_SUCCESS",
  USER_REGISTER_FAILURE = "USER/REGISTER_FAILURE",

  USER_SET = "auth/userSet",

  USER_LOGOUT_REQUEST = "USER/LOGOUT_REQUEST",
  USER_LOGOUT_SUCCESS = "USER/LOGOUT_SUCCESS",
  USER_LOGOUT_FAILURE = "USER/LOGOUT_FAILURE",

  USER_REFRESH_TOKEN_REQUEST = "auth/tokenRefresh",
}

export interface ILoginRequest {
  type: typeof authConstants.USER_LOGIN_REQUEST;
  data: ILogin;
}

export interface ILoginSuccess {
  type: typeof authConstants.USER_LOGIN_SUCCESS;
}

export interface ILoginFailure {
  type: typeof authConstants.USER_LOGIN_FAILURE;
  error: string;
}

export interface IRegisterRequest {
  type: typeof authConstants.USER_REGISTER_REQUEST;
  data: IRegister;
}

export interface IRegisterSuccess {
  type: typeof authConstants.USER_REGISTER_SUCCESS;
}

export interface IRegisterFailure {
  type: typeof authConstants.USER_REGISTER_FAILURE;
  error: string;
}

export interface SetUser {
  type: typeof authConstants.USER_SET;
  currentUser: IUser;
}

export interface LogoutRequest {
  type: typeof authConstants.USER_LOGOUT_REQUEST;
}

export interface LogoutSuccess {
  type: typeof authConstants.USER_LOGOUT_SUCCESS;
}

export interface LogoutFailure {
  type: typeof authConstants.USER_LOGOUT_FAILURE;
  error: string;
}

export interface RefreshToken {
  type: typeof authConstants.USER_REFRESH_TOKEN_REQUEST;
  refresh_token: string;
}
