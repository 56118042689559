/*
 * File Name: App.tsx
 * Purpose:
 * Comments by: Leo Kim (intern)
 */

import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import "./App.scss";
import LoginFormComponent from "./pages/auth/login/LoginForm.component";
import { FocusStyleManager } from "@blueprintjs/core";
import RegisterFormComponent from "./pages/auth/register/RegisterForm.component";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { getTheme, redirectLogin } from "./app/app.slice";
import { ToastContainer } from "react-toastify";
import { loggedIn } from "./pages/auth/_services/Auth.slice";
import { LogoutComponent } from "./pages/auth/login/Logout.component";
import { NotFound } from "./components/Layout/NotFound";
import { AuthLayout } from "./components/Layout/Auth.layout";
import { NetMeteringLayout } from "./components/Layout/NetMetering.layout";
import { NetMetering } from "./net-metering/NetMetering";
import { InitComponent } from "./components/Layout/Init.component";
import { NetMeteringOverview } from "./net-metering/layout/NetMeteringOverview.component";
import { SolbidTariffsTable } from "./net-metering/solbid-tariffs/SolbidTariffs.table";
import { MonthlyOutputs } from "./net-metering/monthly-outputs/MonthlyOutputs";
import { ExcelLikeTable } from "./net-metering/monthly-outputs/ExcelLikeTable";
import { ErrorsView } from "./net-metering/errors/Errors.view";
import { SolarProgramsView } from "./net-metering/solar-programs/SolarPrograms.view";

FocusStyleManager.onlyShowFocusOnTabs();

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useAppSelector(getTheme);
  const isLoggedIn = useAppSelector(loggedIn);
  const redirect_login = useAppSelector(redirectLogin);
  const dispatch = useAppDispatch();
  document.body.className = "bp4-" + theme;
  
  // Effect that runs on activation
  useEffect(() => {
    if (location.pathname !== "/login" && location.pathname !== "/register" && !isLoggedIn) {
      console.log("1");
      navigate("/login");

    // If redirectLogin is true then go to login route
    } else if (redirect_login) {
      console.log("2");
      navigate("/login");

      // If user is already logged in send them to the home route
    }
    if (location.pathname === "/" && isLoggedIn) {
      console.log("3");

      navigate("/net-metering");
    }
  }, [navigate, location, isLoggedIn, redirect_login, dispatch]);

  return (
    <>
      <ToastContainer />
      <InitComponent />
      <Routes>
        {/* ROOT:START */}
        <Route path={"/"} element={<AuthLayout />}>
          <Route index element={<LoginFormComponent />} />
          <Route path="login" element={<LoginFormComponent />} />
          <Route path="logout" element={<LogoutComponent />} />
          <Route path="register" element={<RegisterFormComponent />} />
        </Route>
        {/* ROOT:END */}
        {/* HOME:START */}
        <Route path={"/home"} element={<NetMeteringLayout />}>
          <Route index element={<NetMeteringOverview />} />
        </Route>
        {/* NET METERING:START */}
        <Route path={"net-metering"} element={<NetMeteringLayout />}>
          <Route index element={<NetMeteringOverview />} />
          <Route path="solbid-tariffs" element={<SolbidTariffsTable />} />
          <Route path="test-form" element={<NetMetering />} />
          <Route path="monthly-outputs" element={<MonthlyOutputs />} />
          <Route path="excel" element={<ExcelLikeTable />} />
          <Route path="errors" element={<ErrorsView />} />
          <Route path="solar-programs" element={<SolarProgramsView />} />
        </Route>
        {/* NET METERING:END */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
