import {
  call,
  CallEffect,
  put,
  PutEffect,
  SelectEffect,
  takeLatest,
} from "@redux-saga/core/effects";
import {
  getUtilitiesDropdownRequest,
  getUtilitiesDropdownSuccess,
} from "../NetMetering.slice";

import { utilityProvidersDropdownServices } from "./UtilityProviders.services";

// Workers

function* fetchGetUtilities({
  payload,
}: any): Generator<PutEffect | SelectEffect | CallEffect> {
  try {
    const response = yield call(
      utilityProvidersDropdownServices.getUtilityProviders,
      payload
    );

    yield put(getUtilitiesDropdownSuccess(response));
  } catch (e) {}
}

// Watchers

export function* watchGetUtilityProvidersSaga() {
  yield takeLatest(getUtilitiesDropdownRequest, fetchGetUtilities);
}
