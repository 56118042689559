import { TariffRate } from "./NetMetering.slice";

export interface GeocodeResult {
  address_components: GoogleAddressComponent[];
  formatted_address: string;
  geometry: {};
  place_id: string;
  plus_code: {};
  types: string[];
}

export interface DropdownValues {
  value: string;
  label: string;
}

export interface NetMeteringInterface {
  // zip: string;
  city?: string | undefined;
  zip?: string | undefined;
  location: {
    lat?: number;
    lng?: number;
  };
  taxRate?: string | undefined;
  climate?: string | undefined;
  climateZone?: string | undefined;
  countyFullName?: string | undefined;
  countyName?: string | undefined;
  countyType?: string | undefined;
  humidity?: string | undefined;
  street?: string | undefined;
  annualKwh?: number | undefined;
  buildingSqft?: number | undefined;
  stateAbbr?: string | undefined;
  state?: string | undefined;
  stateId?: number | undefined;
  lseId?: number | undefined;
  buildingTypeId?: number | undefined;
  buildingUseType?: string | undefined;
  solBidProgramType?: number | undefined;
  solarRateType?: number | undefined;
  solarRate?: number | undefined;
  solarProgramCap?: number | undefined;
  tariffId?: number | undefined;
  deliveryFee?: number | undefined;
  supplyFee?: number | undefined;
  demandFee?: number | undefined;
  fixedFee?: number | undefined;
  energyIntensity?: number | undefined;
  maxMonthlyUse?: number | undefined;
  maxDemand?: number | undefined;
  utility: DropdownValues[];
  utilityRates: DropdownValues[];
  buildingTypes: { id: number; value: string; label: string }[];
  tariffRates: TariffRate[];
  selectedUtility: UtilityProvider;
  googleAddress: GoogleAddress;
}

interface NetMeteringInitialStateInterface {
  isLoading: boolean;
  isLoaded: boolean;
  isGenabilityModalOpen: boolean;
  isIntakeModalOpen: boolean;
  error: string | null;
  data: NetMeteringInterface;
  solbidRates: {
    isModalOpen: boolean;
    selectedState: string | null;
    selectedUtility: string | null;
    selectedTariff: SolbidTableTariffs;
    solbidRatesForm: SolbidRates;
    tariffs: SolbidTableTariffs[];
    statesDropdown: DropdownValues[];
    utilitiesDropdown: DropdownValues[];
    tariffsDropdown: DropdownValues[];
    buildingsDropdown: DropdownValues[];
  };
  intake: IntakeData;
  monthlyRates: MonthlyOutput;
}

export type SolbidTableTariffs = {
  tariffId: number;
  lseId: number;
  tariffCode: string;
  tariffName: string;
  isActive: boolean;
  lse?: {
    name: string;
  };
  territory?: {
    stateId: number;
    state?: {
      name: string;
    };
  };
};

export interface SolbidRates {
  id?: number;
  lseId: number;
  tariffId: number;
  custCharge: string;
  distAllTime: string;
  distOnPeak: string;
  distPartialPeak: string;
  distOffPeak: string;
  distOnPeakSummer: string;
  distPartialPeakSummer: string;
  distOffPeakSummer: string;
  suppAllTime: string;
  suppOnPeak: string;
  suppPartialPeak: string;
  suppOffPeak: string;
  suppOnPeakSummer: string;
  suppPartialPeakSummer: string;
  suppOffPeakSummer: string;
  demAllTime: string;
  demOnPeak: string;
  demOffPeak: string;
  demOnPeakSummer: string;
  demOffPeakSummer: string;
}

interface SolarRate {
  solarRateType: number;
  solarRate: number;
  solBidProgramType: number;
}

export interface UtilityProvider {
  lseId: number;
  name: string;
  solarRate: SolarRate;
}

export interface GoogleAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface GoogleAddress {
  address_components: GoogleAddressComponent[];
  formatted_address: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
  place_id: string;
}

export interface IntakeData {
  lat: string;
  lon: string;
  solarSysSize: number;
  azimuth: number;
  arrayTilt: number;
  arrayType: number;
  inverterEfficiency: number;
  moduleType: number;
  sysLoss: number;
  radius: number;
  dcAcRatio: number;
  timeFrame: string;
  buildingTypeId: number;
  annualKwh: number;
  tariffId: number;
  lseId: number;
  zip: string;
  debug: boolean;
}

export interface MonthlyOutput {
  solar: {
    acMonthly: number[];
    onPeak: number[];
    partialPeak: number[];
    offPeak: number[];
    summerOnPeak: number[];
    summerPartialPeak: number[];
    summerOffPeak: number[];
  };
  load: {
    loadsMonthly: number[];
    onPeak: number[];
    partialPeak: number[];
    offPeak: number[];
    summerOnPeak: number[];
    summerPartialPeak: number[];
    summerOffPeak: number[];
    customerCost: number[];
  };
  netExcess: {
    onPeak: number[];
    partialPeak: number[];
    offPeak: number[];
    summerOnPeak: number[];
    summerPartialPeak: number[];
    summerOffPeak: number[];
    monthlyNetExcess: number[];
    monthlyExcessPower: number[];
    monthlyExcessCost: number[];
  };
  solarUtilityBillReductionPreTax: number[];
  solarUtilityBillDemandReductionPreTax: number[];
  totalUtilityBillReductionFromSolarPreTax: number[];
  totalUtilityBillReductionFromSolarPostTax: number[];
  customerUtilityBillPreSolar: number[];
  customerUtilityBillPostSolar: number[];
  acTotal: number;
  utilityBillPostTaxTotal: number;
  utilityBillPostSolarTotal: number;
}

export const initialState: NetMeteringInitialStateInterface = {
  isLoading: false,
  isLoaded: false,
  isGenabilityModalOpen: false,
  isIntakeModalOpen: false,
  error: null,
  data: {
    city: "",
    zip: "",
    location: {
      lat: 0,
      lng: 0,
    },
    taxRate: "",
    stateId: 0,
    lseId: 0,
    buildingTypeId: 0,
    buildingUseType: "",
    solBidProgramType: 0,
    solarRateType: 0,
    solarRate: 0,
    solarProgramCap: 0,
    tariffId: 0,
    buildingSqft: 0,
    state: " ",
    stateAbbr: "",
    climate: "",
    climateZone: "",
    countyFullName: "",
    countyName: "",
    countyType: "",
    humidity: "",
    street: "",
    annualKwh: 0,
    deliveryFee: 0,
    supplyFee: 0,
    demandFee: 0,
    fixedFee: 0,
    energyIntensity: 0,
    maxMonthlyUse: 0,
    utility: [],
    selectedUtility: {
      lseId: 0,
      name: "",
      solarRate: {
        solarRateType: 0,
        solarRate: 0,
        solBidProgramType: 0,
      },
    },
    utilityRates: [],
    buildingTypes: [],
    tariffRates: [],
    googleAddress: {
      address_components: [],
      formatted_address: "",
      geometry: { location: { lat: 0, lng: 0 } },
      place_id: "",
    },
  },
  solbidRates: {
    isModalOpen: false,
    selectedState: null,
    selectedUtility: "0",
    selectedTariff: {
      tariffId: 0,
      lseId: 0,
      tariffCode: "0",
      tariffName: "",
      isActive: false,
    },
    solbidRatesForm: {
      id: 0,
      lseId: 0,
      tariffId: 0,
      custCharge: "0",
      distAllTime: "0",
      distOnPeak: "0",
      distPartialPeak: "0",
      distOffPeak: "0",
      distOnPeakSummer: "0",
      distPartialPeakSummer: "0",
      distOffPeakSummer: "0",
      suppAllTime: "0",
      suppOnPeak: "0",
      suppPartialPeak: "0",
      suppOffPeak: "0",
      suppOnPeakSummer: "0",
      suppPartialPeakSummer: "0",
      suppOffPeakSummer: "0",
      demAllTime: "0",
      demOnPeak: "0",
      demOffPeak: "0",
      demOnPeakSummer: "0",
      demOffPeakSummer: "0",
    },
    tariffs: [],
    statesDropdown: [],
    utilitiesDropdown: [],
    tariffsDropdown: [],
    buildingsDropdown: [],
  },
  intake: {
    lat: "42.65",
    lon: "-71.3",
    solarSysSize: 100,
    azimuth: 180,
    arrayTilt: 10,
    arrayType: 1,
    inverterEfficiency: 96,
    moduleType: 1,
    sysLoss: 14.08,
    radius: 0,
    dcAcRatio: 1.2,
    timeFrame: "monthly",
    buildingTypeId: 0,
    annualKwh: 120000,
    tariffId: 0,
    lseId: 0,
    zip: "01504",
    debug: true,
  },
  monthlyRates: {
    solar: {
      acMonthly: [],
      onPeak: [],
      partialPeak: [],
      offPeak: [],
      summerOnPeak: [],
      summerPartialPeak: [],
      summerOffPeak: [],
    },
    load: {
      loadsMonthly: [],
      onPeak: [],
      partialPeak: [],
      offPeak: [],
      summerOnPeak: [],
      summerPartialPeak: [],
      summerOffPeak: [],
      customerCost: [],
    },
    netExcess: {
      onPeak: [],
      partialPeak: [],
      offPeak: [],
      summerOnPeak: [],
      summerPartialPeak: [],
      summerOffPeak: [],
      monthlyNetExcess: [],
      monthlyExcessPower: [],
      monthlyExcessCost: [],
    },
    solarUtilityBillReductionPreTax: [],
    solarUtilityBillDemandReductionPreTax: [],
    totalUtilityBillReductionFromSolarPreTax: [],
    totalUtilityBillReductionFromSolarPostTax: [],
    customerUtilityBillPreSolar: [],
    customerUtilityBillPostSolar: [],
    acTotal: 0,
    utilityBillPostTaxTotal: 0,
    utilityBillPostSolarTotal: 0,
  },
};
