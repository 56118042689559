import React from "react";

type Props = {
  size: "big" | "small";
  text: string;
};
export const FormDivider = (props: Props) => {
  let fontSize = "";
  let color = "";
  if (props.size === "big") {
    fontSize = "18px";
    color = "#000000";
  }
  if (props.size === "small") {
    fontSize = "14px";
    color = "#565656";
  }
  return (
    <>
      <div style={{ marginBottom: "-10px", fontSize, color }}>
        <b>{props.text}</b>
      </div>
      <hr />
    </>
  );
};
