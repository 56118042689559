import {
  call,
  CallEffect,
  put,
  PutEffect,
  SelectEffect,
  takeLatest,
} from "@redux-saga/core/effects";
import {
  getStatesDropdownRequest,
  getStatesDropdownSuccess,
} from "../NetMetering.slice";
import { statesDropdownServices } from "./StatesDropdown.services";

// Workers

function* fetchGetStates({
  payload,
}: any): Generator<PutEffect | SelectEffect | CallEffect> {
  try {
    const response = yield call(statesDropdownServices.getStates);
    yield put(getStatesDropdownSuccess(response));
  } catch (e) {}
}

// Watchers

export function* watchGetStatesSaga() {
  yield takeLatest(getStatesDropdownRequest, fetchGetStates);
}
