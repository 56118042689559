import React, { useEffect } from "react";
import { Button, ControlGroup, FormGroup, InputGroup } from "@blueprintjs/core";
import { useForm, Controller } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import {
  getBuildingTypesRequest,
  getZipCode,
  isNetMeteringLoading,
  netMeteringRequest,
} from "./NetMetering.slice";
import { SubmittedForm } from "./forms/Submitted.form";
import { GoogleAddressComponent } from "./forms/GoogleAddress.component";

interface NetMeteringInterface {
  zip: string;
}

const NetMeteringSchema = Yup.object().shape({
  zip: Yup.string().required("Zip Code is required"),
});

export const NetMetering = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isNetMeteringLoading);
  const zip = useAppSelector(getZipCode);

  const {
    control,
    handleSubmit,
    //register,
    //watch,
    //formState,
    formState: { errors },
    reset,
  } = useForm<NetMeteringInterface>({
    resolver: yupResolver(NetMeteringSchema),
    defaultValues: { zip },
  });

  useEffect(() => {
    dispatch(getBuildingTypesRequest());
    reset({ zip });
  }, [reset, zip, dispatch]);

  const onSubmit = handleSubmit((data) => {
    dispatch(netMeteringRequest(data));
  });

  return (
    <>
      <div className="bp4-dialog-container">
        <div className="bp4-dialog" style={{ width: "700px" }}>
          <div className="bp4-dialog-header">
            <span className="bp4-icon-large bp4-icon-inbox"></span>
            <h5 className="bp4-heading">Net Metering</h5>
          </div>
          <div
            className="bp4-dialog-body"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <GoogleAddressComponent />
            <form onSubmit={onSubmit}>
              <div>
                <Controller
                  control={control}
                  name="zip"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <FormGroup
                      label="Zip Code"
                      labelFor={"zip"}
                      labelInfo={"(required)"}
                      style={{ width: "160px" }}
                    >
                      <ControlGroup>
                        <>
                          <InputGroup
                            id={name}
                            placeholder="Zip code..."
                            style={{ width: "100px" }}
                            type={"text"}
                            name={name}
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            inputRef={ref}
                            readOnly={false}
                          />
                          <p className={"validationMessage"}>
                            {errors.zip?.message}
                          </p>
                        </>
                        <Button
                          intent={"primary"}
                          icon="arrow-right"
                          loading={isLoading}
                          type={"submit"}
                        />
                      </ControlGroup>
                    </FormGroup>
                  )}
                />
              </div>
            </form>
          </div>
          <SubmittedForm />
        </div>
      </div>
    </>
  );
};
