import { dispatchRefreshToken } from "../pages/auth/_services/Auth.actions";
import axios from "axios";
import rootVars from "./root.vars";
import { authService } from "../pages/auth/_services/Auth.services";

export const axiosNemConfig = {
  headers: {
    "X-API-KEY": "f6abf507-2141-4462-acc3-a5d882def27d",
  },
};

const HTTP = axios.create({
  baseURL: rootVars.baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

HTTP.interceptors.request.use(
  (config: any) => {
    const security = authService.getLocalAccessToken();
    if (security) {
      config.headers.Authorization = `${security.type} ${security.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

HTTP.interceptors.response.use(
  (response) => response,
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/auth/signup" && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const response = await HTTP.post("/auth/refreshtoken", {
            refresh_token: authService.getLocalRefreshToken(),
          });

          const { payload } = response.data;

          dispatchRefreshToken(payload.token);
          authService.updateLocalAccessToken(payload.token);

          return HTTP(originalConfig);
        } catch (error) {
          authService.logout();
          return Promise.reject(error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default HTTP;
