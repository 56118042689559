import React from "react";
import { Button, Card, Elevation, HTMLTable } from "@blueprintjs/core";
import { Styles } from "../../styles/main.styled";
import { CardHeader } from "../../components/Card/CardHeader";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getOnlineTariffsRequest,
  getSelectedUtility,
  getSolbidTariffRequest,
  getTariffsTable,
  isNetMeteringLoading,
  regenerateSolbidRatesRequest,
} from "../NetMetering.slice";
import { StatesDropdownComponent } from "../states/StatesDropdown.component";
import { UtilitiesDropdownComponent } from "../utility-providers/UtilitiesDropdown.component";
import { SolbidTariffForm } from "./SolbidTariff.form";
import { TariffRatesDetails } from "../dialogs/TariffRatesDetails";

export const SolbidTariffsTable = () => {
  const dispatch = useAppDispatch();
  const tariffs = useAppSelector(getTariffsTable);
  const selectedUtility = useAppSelector(getSelectedUtility);
  const isLoading = useAppSelector(isNetMeteringLoading);

  return (
    <>
      <br />
      <br />
      <Styles.CardContainerHorizontal>
        <StatesDropdownComponent />
        <UtilitiesDropdownComponent />

        {tariffs.length === 0 && selectedUtility !== "0" ? (
          <Button
            intent={"primary"}
            icon="refresh"
            loading={isLoading}
            type={"button"}
            onClick={() => dispatch(getOnlineTariffsRequest(selectedUtility))}
          />
        ) : null}

        <Button
          intent={"danger"}
          icon="refresh"
          type={"button"}
          loading={isLoading}
          text={"Regenerate Solbid Rates"}
          onClick={() => dispatch(regenerateSolbidRatesRequest())}
        />
      </Styles.CardContainerHorizontal>
      <Styles.CardContainer>
        <Card elevation={Elevation.TWO} style={{ padding: "0", width: "94%" }}>
          <CardHeader
            leftIcon={"helper-management"}
            title={"All Tariffs - Active"}
          />

          <HTMLTable interactive={true} bordered={true} striped={true}>
            <thead>
              <tr>
                <th style={{ width: "100px" }}>tariffId</th>
                <th style={{ width: "100px" }}>lseId</th>
                <th style={{ width: "280px" }}>tariffCode</th>
                <th>tariffName</th>
              </tr>
            </thead>
            <tbody>
              {tariffs.map((row, index) =>
                row.isActive ? (
                  <tr
                    key={index}
                    onClick={() => dispatch(getSolbidTariffRequest(row))}
                  >
                    <td>{row.tariffId}</td>
                    <td>{row.lseId}</td>
                    <td>{row.tariffCode}</td>
                    <td>{row.tariffName}</td>
                  </tr>
                ) : null
              )}
            </tbody>
          </HTMLTable>
        </Card>
      </Styles.CardContainer>

      <Styles.CardContainer>
        <Card
          elevation={Elevation.TWO}
          style={{ padding: "0", width: "94%", backgroundColor: "#fea5b647" }}
        >
          <CardHeader
            leftIcon={"helper-management"}
            title={"All Tariffs - Inactive"}
          />

          <HTMLTable interactive={true} bordered={true} striped={true}>
            <thead>
              <tr>
                <th style={{ width: "100px" }}>tariffId</th>
                <th style={{ width: "100px" }}>lseId</th>
                <th style={{ width: "280px" }}>tariffCode</th>
                <th>tariffName</th>
              </tr>
            </thead>
            <tbody>
              {tariffs.map((row, index) =>
                row.isActive ? null : (
                  <tr
                    key={index}
                    onClick={() => dispatch(getSolbidTariffRequest(row))}
                  >
                    <td>{row.tariffId}</td>
                    <td>{row.lseId}</td>
                    <td>{row.tariffCode}</td>
                    <td>{row.tariffName}</td>
                  </tr>
                )
              )}
            </tbody>
          </HTMLTable>
        </Card>
      </Styles.CardContainer>
      <SolbidTariffForm />
      <TariffRatesDetails />
    </>
  );
};
