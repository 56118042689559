import * as actions from "./Auth.constants";
import { ILogin, IRegister, IUser } from "./Auth.interfaces";
import { store } from "../../../app/store";

export const login = (data: ILogin): actions.ILoginRequest => ({
  type: actions.authConstants.USER_LOGIN_REQUEST,
  data,
});

export const loginSuccess = (): actions.ILoginSuccess => ({
  type: actions.authConstants.USER_LOGIN_SUCCESS,
});

export const loginFailure = (error: string): actions.ILoginFailure => ({
  type: actions.authConstants.USER_LOGIN_FAILURE,
  error,
});

export const register = (data: IRegister): actions.IRegisterRequest => ({
  type: actions.authConstants.USER_REGISTER_REQUEST,
  data,
});

export const registerSuccess = (): actions.IRegisterSuccess => ({
  type: actions.authConstants.USER_REGISTER_SUCCESS,
});

export const registerFailure = (error: string): actions.IRegisterFailure => ({
  type: actions.authConstants.USER_REGISTER_FAILURE,
  error,
});

export const setUser = (currentUser: IUser): actions.SetUser => ({
  type: actions.authConstants.USER_SET,
  currentUser,
});

export const logoutRequest = (): actions.LogoutRequest => ({
  type: actions.authConstants.USER_LOGOUT_REQUEST,
});

export const logoutSuccess = (): actions.LogoutSuccess => ({
  type: actions.authConstants.USER_LOGOUT_SUCCESS,
});

export const logoutFailure = (error: string): actions.LogoutFailure => ({
  type: actions.authConstants.USER_LOGOUT_FAILURE,
  error,
});

export const dispatchRefreshToken = (
  refresh_token: string
): actions.RefreshToken =>
  store.dispatch({
    type: actions.authConstants.USER_REFRESH_TOKEN_REQUEST,
    refresh_token,
  });
