import React from "react";
import { FormGroup, InputGroup } from "@blueprintjs/core";
import { usePlacesWidget } from "react-google-autocomplete";
import { useAppDispatch } from "../../app/hooks";
import { processStreet } from "../NetMetering.slice";

export const GoogleAddressComponent = () => {
  const dispatch = useAppDispatch();
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyAy_rwJdIJDflw6BI32YUhomjRlXAUKMgg",
    onPlaceSelected: (place) => dispatch(processStreet(place)),
    options: {
      types: [],
      componentRestrictions: { country: "us" },
    },
  });

  return (
    <>
      <FormGroup
        label="Street Search"
        labelFor={"test_street"}
        labelInfo={"*"}
        style={{ width: "420px", marginRight: "16px" }}
      >
        <InputGroup
          id={"test_street"}
          placeholder="Street Name"
          type={"text"}
          name={"test_street"}
          // @ts-ignore
          inputRef={ref}
          readOnly={false}
        />
      </FormGroup>
    </>
  );
};
